import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Obituary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Obituary" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

<div className="main_body">

<div className="articleTop">
<div className="TBDtitle">This is my dad’s story</div>
<div className="article_top_byline">By <span className="TBDbyline">Bill Gates</span><span className="TBDdate">September 16, 2020</span></div>

</div>

<p><em>My dad died on Monday of Alzheimer’s disease. He was 94. My dad’s wife Mimi, my sisters, and I have been sharing this obituary, which our family worked on together, with friends and thought I would post it here too. It gives you a sense of the kind of man Dad was and why we all feel so lucky to have had him in our lives. You can also read my own tribute to him <a href="https://www.gatesnotes.com/About-Bill-Gates/Remembering-Bill-Gates-Sr" target="_blank">here</a>. – Bill Gates</em></p>
<p>The Gates family mourns the passing of William H. Gates II, known to many as Bill Sr., who died peacefully at his beach home on Hood Canal on September 14, 2020 of Alzheimer’s disease. In his 94 years, Bill created a towering legacy as a lawyer, philanthropist, and activist. To his family, he was a beloved husband, father, and grandfather who was an example of what it means to be a model citizen and a profoundly decent man. To many in the community, he was an inspiring role model, trusted advisor, and loyal friend. His sincerity, humor, approachability, and thoughtfulness won him legions of admirers and made him highly effective in his work and his civic and philanthropic endeavors.</p>
<p>Bill was born on November 30, 1925 and grew up in the Navy town of Bremerton. His father owned a furniture store and neither of his parents went to high school. Bill went to Bremerton High School and was active in Boy Scouts, where his troop spent three summers building a log cabin from the ground up, and he learned his first lesson about the power of people working toward a common goal.</p>
<p>He completed one year at the University of Washington before reporting for duty in the U.S. Army. He completed officer candidate school and was en route to Japan when it surrendered. He spent a year in war-torn Tokyo, which provided him with one of his first opportunities to consider our shared humanity and its ability to unite people regardless of their backgrounds.</p>
<p>Gates returned to the UW to earn an undergraduate degree in 1949 and a law degree the next year. During that time, he gained a lifelong passion for the Husky Nation and met his first wife, Mary Maxwell. Bill and Mary raised two daughters and a son and developed a shared sense of civic duty. Volunteering and philanthropy were nightly topics of conversation around the Gates family dinner table.</p>
<p style={{marginRight: '11.6pt'}}>Bill and Mary found great joy in nurturing friendships. They were prolific entertainers, and guests could count on meaningful conversation and friendly competition. They and eight other families spent more than 10 summers on Hood Canal at Cheerio resort, where Bill was fondly known as “the mayor.” For several years, they held a holiday skating party where Bill, at 6’7”, could be found wearing a Santa suit on roller skates. Their bridge club met for nearly 60 years.</p>
<p>Bill began his legal career working in private practice and serving part-time as City Attorney for Bremerton. He joined a Seattle law firm, and after 12 years, formed a new firm with two partners. As managing partner, Gates helped grow the firm and today it is known as K&amp;L Gates, one of the world's largest law firms. His firm was involved early in the region’s tech industry, and that involvement expanded over time. Gates retired from the firm, then known as Preston Gates &amp; Ellis, in 1998 after 48 years in practice.</p>
<p>Bill believed in the power of the law to help people and make change for the better. His colleagues admired his integrity, trusted his intelligence, and sought his advice. He engaged in the law’s professional associations, including serving as president of the local and state bar associations, holding leadership positions with the American Bar Association, participating in several court commissions, and other posts. Through these associations, he helped create law school scholarships for people of color and an approach to delivering legal services to the poor.</p>
<p>In addition to being one of Seattle’s most respected lawyers, Bill became one of the city’s most revered civic leaders. He served as an engaged trustee of numerous organizations, including the Greater Seattle Chamber of Commerce, the King County Chamber of Commerce, Planned Parenthood, and United Way of King County. He founded the Technology Alliance and helped boost technology employment in Washington. He chaired the Seattle Public Schools Levy and was a visible advocate for progressive taxation, including initiating a state income tax on the very wealthy, and maintaining an estate tax— reflections of his deep commitment to social and economic equity.</p>
<p>In <em>Showing Up for Life</em>, Bill wrote that “one way to sum up a lifetime quickly is to think of the right words to put on your tombstone.” He thought his should read: “He Married Well.” Despite the intense sorrow of losing Mary to cancer in 1994, he never took for granted how lucky he was to have nearly five decades with her. And then, after Mary passed, he was astonished to find that he could love fully again, when he met and married Mimi Gardner in 1996. Bill and Mimi spent more than 24 years traveling the world, learning, laughing, enjoying life, and simply being together. Mimi and Bill spent his final months at Hood Canal where they could enjoy beautiful views and time outside.</p>
<p>Bill also had a profound impact on the Bill &amp; Melinda Gates Foundation and the people the foundation serves. He, more than anyone else, shaped the values of the foundation. He started the foundation’s work in global health, created the Gates Cambridge Scholars Trust (2000), and guided the foundation’s major investments in human services, education, and culture in the Pacific Northwest. He was quick to tear up when he saw suffering in the world and never let anyone forget the human beings behind the foundation’s strategies. He saw the best in everyone, made everyone feel special, and upheld the foundation motto “All Lives Have Equal Value” in every way possible.</p>
<p>During his lifetime, Bill received innumerable awards including YMCA’s A.K. Guy Award, the Seattle King County REALTORS First Citizen Award, the Distinguished Eagle Scout Award, and the American Bar Association Medal. The University of Washington selected him UW’s Alumnus Summa Laude Dignatus in recognition of his service to and insatiable love for the University. He served as UW Regent for 15 years and directed Campaign UW, which raised $2.7 billion through 293,000 donors. A UW Regent resolution saluted Bill as “patriarch of the first family of the University.” Even in the later stages of Alzheimer’s, Bill could sing the lyrics to “Bow Down to Washington.”</p>
<p>In his characteristically humble way, Bill never saw his actions as anything special—simply the right thing to do—though the community is filled with people and organizations who were changed for the better because of his involvement.</p>
<p>The family would like to thank the tremendously loving and caring people, affectionately named “Team Bill,” who cared for him in the last years of his life. The quality of his life was greatly enhanced by their presence and consistent provision of hot (very hot) coffee, chocolate brownies, and bowl after bowl of chocolate ice cream.</p>
<p>In addition to Mimi, Bill is survived by his children, Kristianne (Kristi) Blake, William (Bill) H. Gates III, and Elizabeth (Libby) MacPhee; their spouses, John Blake, Melinda French Gates, and Nicholas MacPhee; and his grandchildren, Kerry and Sully Blake, Jennifer, Rory and Phoebe Gates, and Emmy, Steve and Mary Armintrout. The family will carry on the traditions that have left a lasting imprint, including matching pajamas at Christmas, time on Hood Canal, and competition and games. His grandchildren will cherish memories of their individual “10-year-old trips” with Poppy and Mimi.</p>
<p>His son, Bill Gates III, once wrote: “The next time someone asks you if you’re the real Bill Gates, tell them you’re all the things the other one strives to be.”</p>
<p>Because of COVID-19 restrictions, the Gates family will hold a memorial service at a later date. To honor Bill’s lifetime of service and generosity, donations may be made to <a rel="noopener noreferrer" href="https://www.landesa.org/" target="_blank">Landesa Rural Development Institute</a>, <a rel="noopener noreferrer" href="https://online.gifts.washington.edu/peer2peer/campaign/bill-gates-sr-memorial" target="_blank">University of Washington – Achievement Scholars Program &amp; Endowment</a>, the <a rel="noopener noreferrer" href="https://www.ywcaworks.org/" target="_blank">YWCA Seattle-King- Snohomish</a>, or to a charity of your choice.</p>
<p>&nbsp;</p>
            </div>
            </div>
        );
    }
}

export default Obituary;