import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Stories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Bar" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">
<div className="articleTop">
                    <div className="TBDtitle">Stories</div>
</div>
<p>Associated Press: <a href="https://apnews.com/article/bill-gates-melinda-gates-washington-archive-c40e91c47e8c0e72d98db3f5ac5f6e91" target="_blank">Bill Gates Sr., father of Microsoft co-founder, dies at 94</a> (syndicated to more than 250 news outlets)</p>
<p>Bloomberg: <a href="https://www.bloomberg.com/news/articles/2020-09-15/bill-gates-sr-father-of-microsoft-s-co-founder-dies-at-94?utm_source=google&utm_medium=bd&cmpId=google" target="_blank">Bill Gates Sr., Father of Microsoft’s Co-Founder, Dies at 94</a></p>
<p>The Daily (UW): <a href="http://www.dailyuw.com/news/article_bf89b0de-0139-11eb-b07e-1ff3a25032fa.html" target="_blank">Remembering Bill Gates Sr., law alum who made UW education a force for social good</a></p>
<p>Gates Notes: <a href="https://www.gatesnotes.com/About-Bill-Gates/Remembering-Bill-Gates-Sr" target="_blank">Remembering my father</a></p>
<p>GeekWire: <a href="https://www.geekwire.com/2020/bill-gates-sr-1925-2020-microsoft-co-founders-father-made-mark-seattle-world/" target="_blank">Bill Gates Sr., 1925-2020: Microsoft co-founder’s father made his own mark on Seattle and the world</a></p>
<p>King County Bar Bulletin: <a href="http://www.kcba.org/For-Lawyers/Bar-Bulletin/PostId/1242/in-memoriam-william-gates-sr" target="_blank">In Memoriam – William Gates Sr.</a></p>
<p>New York Times: <a href="https://www.nytimes.com/2020/09/15/business/bill-gates-sr-dead.html" target="_blank">Bill Gates Sr., Who Guided Billionaire Son’s Philanthropy, Dies at 94</a></p>
<p>Puget Sound Business Journal: <a href="https://www.bizjournals.com/seattle/news/2020/09/15/bill-gates-sr-dies-at-94.html" target="_blank">Bill Gates Sr., father of Microsoft co-founder, dies at 94</a></p>
<p>Seattle Times: <a href="https://www.seattletimes.com/seattle-news/obituaries/bill-gates-sr-civic-figure-and-father-of-microsoft-co-founder-dies-at-94/" target="_blank">Bill Gates Sr., civic figure and father of Microsoft co-founder, dies at 94</a></p>
<p>Seattle Times: <a href="https://www.seattletimes.com/opinion/editorials/celebrate-the-phenomenal-legacy-of-bill-gates-sr/" target="_blank">Celebrate the phenomenal legacy of Bill Gates Sr. (editorial board)</a></p>
<p>University of Washington: <a href="https://www.washington.edu/news/2020/09/15/the-university-of-washington-mourns-the-loss-of-bill-gates-sr/" target="_blank">The University of Washington mourns the loss of Bill Gates, Sr.</a></p>
<p>Washington Post: <a href="https://www.washingtonpost.com/local/obituaries/william-gates-sr-a-guiding-hand-of-bill-and-melinda-gates-foundation-dies-at-94/2020/09/15/4715b8c8-9456-11ea-91d7-cf4423d47683_story.html" target="_blank">William Gates Sr., a guiding hand of Bill & Melinda Gates Foundation, dies at 94</a></p>
<br/><br/><br/><br/>
            </div>
            </div>
        );
    }
}

export default Stories;