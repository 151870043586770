import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class TermsOfUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }
  unsubscribe() {
  }

  componentWillUnmount() {
  }

  componentDidMount() {
  }
  componentDidUpdate() {
  }

  render() {
    return (
      <div>
        <div className="main_body">
         
        <p><strong>Terms of Use</strong></p>
        <p><strong>Effective Date: 10/12/2020</strong></p>
        
 <p>Welcome to The Gates Notes LLC (&ldquo;<strong>Gates Notes</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;) William H. Gates II Memorial website, (<a href="https://www.giftsofalife.family">https://www.giftsofalife.family</a>) (the &ldquo;<strong>Site</strong>&rdquo;). These Terms of Use (&ldquo;Terms&rdquo;) are a legal agreement between you, a user of the site (&ldquo;<strong>you</strong>&rdquo; or &ldquo;<strong>your</strong>&rdquo;), and Gates Notes that governs your use of the Site, including all related webpages, and downloadable materials, information, photos, or other documentation (&ldquo;<strong>Content</strong>&rdquo;) that appears on the Site. Your access to the Site is conditioned upon your acceptance of these Terms and our Privacy Policy, which is incorporated into these Terms by this reference. Please read our Terms of Use and Privacy Policy carefully. IF YOU DO NOT AGREE WITH THESE TERMS, YOU SHOULD NOT ACCESS OR USE THE SITE OR REGISTER TO BECOME A GATES NOTES INSIDER.</p>
 <ol type="1">
   <li><strong>Privacy Policy</strong>
<p>Please review our&nbsp;<a href="/privacy-policy">Privacy Policy</a>, which is part of these Terms of Use and describes how we handle any personally identifying information about Site users. By accessing, browsing, or using the Site, you expressly consent to the collection, use, storage, processing, and disclosure of your information, including personally identifiable information, as described in our Privacy Policy.</p></li>
   <li><strong>Tribute submission policy</strong>
<p>The Site maintains a Tribute section to provide a forum for sharing memories and reflections on the life of William H. Gates II. All statements or other User Content posted by contributors reflects the views of the individual contributors and does not reflect the views of Gates Notes. Gates Notes takes no responsibility and assumes no liability for any User Content posted by you or any third party.<br/>
 Gates Notes reserves the right to review your tribute submission before posting it to the Tribute section. We also reserve the right to not publish your tribute on the Site for any reason. We encourage respectful, constructive dialogue and reserve the right to remove tributes that are offensive, act as advertisements, or attempt to solicit funds. We also may remove identical or substantially similar tributes that are posted more than once.</p></li>
   <li><strong>Email address submission</strong>
<p>In order to submit a tribute, the Site requires you to provide your email address to us in order to receive certain communications from us regarding your submission. Your email address will not be used for any other purpose.</p></li>
   <li><strong>User-generated content</strong>
<p>The Site may permit you to publicly post content, such as your name and a tribute (&ldquo;<strong>User Content</strong>&rdquo;). Gates Notes does not control or endorse and is not responsible or liable for User Content. If you post any User Content to the Site, you grant Gates Notes and all members of the public who may access publicly-available User Content on the Site a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display that User Content throughout the world in any media, and the right to use and display the name that you submit in connection with such User Content. By posting User Content, you represent and warrant that you own or otherwise control all of the rights to the User Content that you post; that the User Content is accurate; that use of the User Content you supply does not violate any law or this policy and will not cause injury to any person or entity. You must not provide any User Content that infringes others&rsquo; copyrights or other intellectual property or privacy rights. You are solely responsible for all User Content you provide to Gates Notes or make available through the Site.</p></li>
   <li><strong>Copyright</strong>
<p>Gates Notes owns or licenses the copyright in all the materials on the Site, including text, photographs, graphics, logos, button icons, audio, video and software, and those rights are protected by United States and international copyright laws and other intellectual property laws. If you believe that your work has been copied in a way that constitutes copyright infringement, please follow our Notice and Procedure for Making Claims of Copyright Infringement, below.</p></li>
   <li><strong>Copying or Distribution is Prohibited</strong>
<p>You may not copy or distribute, or publicly perform, publicly display or create derivative works of the materials and Content that appear on the Site.  You are permitted to share a link to the Site.</p></li>
   <li><strong>Copyright infringement&nbsp;–&nbsp;notification requirements</strong>
<p>If you believe that your work has been used on our Site in a way that constitutes copyright infringement, you should send written notification thereof, in accordance with the provisions of the Digital Millennium Copyright Act (the &ldquo;<strong>Notification</strong>&rdquo;), to our Designated Copyright Agent, who can be reached as follows:<br/>
 Email:&nbsp;legal@gatesventures.com</p>
<p>Phone: (425) 497-4300<br/>
 The Gates Notes, LLC<br/>
 c/o Gates Ventures, LLC<br/>
 4110 Carillon Point<br/>
 Kirkland, WA 98033<br/>
 <br/>
 Pursuant to 17 U.S.C. § 512(c), to be effective, the Notification must include the following: </p>
<ol type="A">
 <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. </li>
 <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site. </li>
 <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material. </li>
 <li>Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address. </li>
 <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law. </li>
 <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. </li>
</ol>
<p>This process only relates to reporting a claim of copyright infringement. Messages related to other matters will not receive a response through this process. Please note that under Section 512(f) of the Digital Millennium Copyright Act, any person who knowingly and materially misrepresents that material is infringing may be subject to liability. If you are unsure whether material on the Site is infringing, we suggest that you contact an attorney prior to sending notice.</p></li>
   <li><strong>Links, frames, and metatags</strong>
<p>You may link to the home page of the Site as long as you do not do so in a false or misleading manner. You may not frame the content of the Site. You may not use metatags or any other &ldquo;hidden text&rdquo; that incorporates our Site&rsquo;s contents without our express written consent.</p></li>
   <li><strong>Links to other websites</strong>
<p>The Site contains links to other websites that we think may be of interest to you. We do not endorse or sponsor any third party websites or the information, products, or services contained on any third party websites and we have no control over third party websites or their content. Remember that when you link to or share Content on another website, that other website is governed by its own user agreement and privacy statement, which you should be sure to read. Access to and use of any third party website is solely at your own risk.</p></li>
   <li><strong>Site availability and support</strong>
<p>You may access the Site if and when it is available. We do not guarantee availability of the Site or of materials on the Site. The Site may occasionally be down for service, upgrades, or for other reasons. To the maximum extent authorized under applicable law, we reserve the right to change, remove, delete, restrict, block access to, or stop providing any or all of the Site at any time and without notice. We have no obligation to provide support in relation to the Site or Content.</p></li>
   <li><strong>Disclaimer of warranties</strong>
<p>To the maximum extent permitted by applicable law, the site, including all text, graphics, logos, audio and video clips, photographs, and other content is provided &ldquo;as is,&rdquo; &ldquo;with all faults,&rdquo; and &ldquo;as available&rdquo; and the entire risk of use and performance remains with you. We do not make any representations, warranties, or conditions, express, implied, or statutory and hereby disclaim any and all warranties with respect to the site. In particular, we make no warranty that the site or content: (a) will meet your requirements; (b) will be available or provided on an uninterrupted, timely, secure, or error-free basis; (c) will be accurate, complete, or reliable; or (d) will be free from viruses, worms, or other harmful or malicious components. Nor do we warrant that any defects or errors on the site or content will be corrected. We do not assume any liability relating to delays or interruptions attributable to third party failures beyond our control. The site and all content or materials you download or obtain from the site is accessed at your own risk, and you will be solely responsible for any damage or loss that results therefrom.</p></li>
   <li><strong>Disclaimer of certain damages</strong>
<p>To the maximum extent permitted by applicable law, in no event will we be liable for any consequential, special, incidental, indirect, or punitive damages of any kind, whether foreseeable or not, arising out of or in any way connected with the site or these terms, even if we have been advised of the possibility of such damages.</p></li>
   <li><strong>Limitation of liability and exclusive remedies</strong>
<p>To the maximum extent permitted by applicable law and to the extent they are not excluded or disclaimed under sections 12 or 13, our maximum, aggregate liability to you, and your exclusive remedy under these terms for any and all damages, injuries, and losses arising from any and all claims and causes of action arising out of, based on, resulting from, or in any way related to these terms or the site shall be limited to five dollars ($5.00). The existence of multiple claims or suits under or related to these terms or the site will not enlarge or extend the limitation of money damages.</p></li>
   <li><strong>Independent remedies</strong>
<p>The exclusion of damages under Section 13 is independent of your exclusive remedy in Section 14 and it survives even if the exclusive remedy fails of its essential purpose or otherwise is deemed unenforceable. Each of the limitations of liability in Sections 12, 13, and 14 apply without regard to whether loss, liability, or damage arise from (a) breach of contract, (b) breach of warranty, (c) fault or tort, including negligence and misrepresentation, (d) strict liability, or (e) any other cause of action, to the extent the exclusions and limitations are not prohibited by applicable law.</p></li>
   <li><strong>Notice on potential limits of sections 12, 13, and 14</strong>
<p>Some jurisdictions do not allow the exclusion or limitation of damages (including incidental or consequential), loss, or liability from intentional acts (including fraud, fraudulent misrepresentation, and failure to disclose defects), product liability, or for death or personal injury. Nothing in Sections 12, 13, or 14 will be interpreted as excluding liability which cannot under applicable law be excluded in those jurisdictions. If you reside, or are otherwise subject to the laws in one of those jurisdictions, any statutory entitlement available to you will be deemed limited to the extent (if at all) permissible under that law and, if limitation is not permitted, the limitations and exclusions in one or more of those Sections may not apply to you.</p></li>
   <li><strong>Indemnification</strong>
<p>You hereby agree to defend, indemnify, and hold the Gates Notes, its directors, officers, employees, agents, partners, suppliers, and licensors harmless and will keep them indemnified from any third party claims or demands, including reasonable attorneys&rsquo; fees, relating to, arising from, or allegedly arising from (a) your use of the Site; (b) any violation by you of these Terms; or (c) your violation of any other party&rsquo;s rights or applicable law.</p></li>
   <li><strong>Notices</strong>
<p>We may give you all required notices (including legal process) by any lawful method, including by posting notices on the Site or by sending notice to any email address you provide to us. You agree to send notices to us by emailing them to the following address:&nbsp;<a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>.</p></li>
   <li><strong>Changes to these Terms</strong>
<p>We reserve the right to change these Terms at any time, in our sole discretion, upon notice to you. We may give notice by posting updated Terms on the Site, sending you an email, or by any other reasonable means. You should periodically review these Terms for changes and you can review the most current Terms at any time at:&nbsp;________________. The updated Terms will govern your use of the Site as of the &ldquo;Effective Date&rdquo; date indicated at the top of these Terms. If you do not agree to the updated Terms, you should stop using the Site. Your use of the Site after the updated version of these Terms is posted will constitute your acceptance of the updated Terms.</p></li>
   <li><strong>Termination</strong>
<p>We reserve the right to terminate the Site and this User Agreement at any time without notice for any reason, including for your violation of the User Discussion Policy, these Terms, or our Privacy Policy. The Limitation of Liability and Governing Law Sections of this User Agreement survive any such termination.</p></li>
   <li><strong>Governing law and exclusive jurisdiction and venue</strong>
<p>These Terms and your use of the Site is governed by the laws of the State of Washington applicable to contracts made and performed there, without regard to its conflicts of law principles. You expressly agree that jurisdiction and venue for any dispute relating to or arising from these Terms, Content, or the Site will reside in the State and Federal Courts of King County, Washington.</p></li>
   <li><strong>International use</strong>
<p>Gates Notes&rsquo; servers and operations are located primarily in the United States and our policies and procedures are based primarily on United States law. Because of this, the following provisions apply specifically to users located outside of the United States: (i) you consent to the transfer, storage, and processing of your information (including Submissions and Personal Data) to and in the United States and/or other countries; (ii) if you are using the Services from a country embargoed by the United States, or are on the United States Treasury Department&rsquo;s list of &ldquo;Specially Designated Nationals,&rdquo; you are not authorized to access or use the Services; and (iii) you agree to comply with all local laws, rules, and regulations including all laws, rules, and regulations in effect in the country in which you reside and the country from which you access the Services. The Services are not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation, or that would subject Gates Notes or its affiliates to any registration requirement within such jurisdiction or country.</p></li>
   <li><strong>General</strong>
<p>If any provision of these Terms is determined by a court of competent jurisdiction to be illegal, invalid, or unenforceable, the remaining provisions of these Terms will remain in full force and effect. Section titles are only for convenience and have no legal or contractual significance. We may assign these Terms, in whole or in part, at any time, with or without notice to you. You may not assign, transfer, or sublicense your rights, if any, in the Site. If, at any time, we fail to respond to a breach of these Terms by you or others, such failure will not waive our right to act with respect to subsequent or similar breaches. A waiver will only be binding on us if it is in writing and signed by us. These Terms (including any incorporated terms or policies) constitute the entire agreement between you and Gates Notes with respect to the Site. Both you and Gates Notes warrant to each other that, in entering these Terms, neither you nor Gates Notes have relied on or will have any right or remedy based upon any statement, representation, warranty, or assurance other than those expressly stated in these Terms. The preceding sentence will not limit or exclude any liability that cannot be limited or excluded under applicable law. No one other than you and Gates Notes or Gates Notes&rsquo; successors and assigns, will have any right to enforce any of these Terms. You and gates notes agree that any cause of action arising out of or related to the site or site materials must commence within one (1) year after the cause of action accrues; otherwise, such cause of action is permanently barred.</p></li>
   <li><strong>Comments and questions</strong>
<p>If you have any questions, comments or concerns about the Site, including materials appearing on the Site, please contact us via email at <a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>.</p><br/><br/></li>
   </ol>













          </div>
      </div>
    );
  }
}

export default TermsOfUse;