import React, { Component } from 'react';
import { client } from './config';
import { Subject, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


let unsubscribeSubject = new Subject();

class TributeItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      TribItems: null
    };
  }

  fetchTribItems(slug) {
    client.items()
      .type('modular_tribute')
      .elementsParameter(['name', 'tribute', 'datetime', 'sortorder', 'moderation', 'curated_display_name'])
      .toObservable()
      .pipe(takeUntil(unsubscribeSubject))
      .subscribe(response => {
        //console.log("TL:");
        //console.log(response.items);
        this.setState({
          loaded: true,
          tributes: response.items
        });
      });
  }
  unsubscribe() {
    unsubscribeSubject.next();
    unsubscribeSubject.complete();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    let slug = this.props.match.params.slug;
    this.fetchTribItems(slug);
  }
  componentDidUpdate() {
    this.resizeFunction();
    window.addEventListener("resize", this.resizeFunction.bind(this));
}
resizeFunction() {
  var quotes = document.getElementsByClassName("TribItem");
  var totalH = 0;
  var pageW = window.innerWidth;
for (var i = 0; i < quotes.length; i++) {
var TIh = quotes[i].offsetHeight;
totalH += TIh;
}
var TributesHolder = document.getElementById("TributesHolder");
if (TributesHolder != null) {
  if (pageW > 680) {
    TributesHolder.style.height = (totalH*.8)+"px";
  } else {
    TributesHolder.style.height = (totalH*1.2)+"px";
  }
}
}
  render = (props) => {
    if (this.state.loaded) {
      //const sortedActivities = this.state.tributes.sort((a, b) => b.datetime.value - a.datetime.value)
      const sortedActivities = this.state.tributes.sort((a, b) => (b.sortorder.value - a.sortorder.value) || (b.datetime.value - a.datetime.value));
      return (
        <div
          className="TributesHolder grid" id="TributesHolder"
        >
          {sortedActivities.map((tribute) => {
            var pinned = false;
            var defaultname = true;
            var NameDateClass = "TribNameDate";
            var NameClass = "TribName";
            var DateClass = "TribDate";
            var Chim = " |";
            if (tribute.moderation.value[0].name != "Pending") {
              var name = tribute.name.value;
              var names = name.split(' ');
              if (names.length > 1) {
                name = names[0] + " " + names[1].substring(0, 1);
              } else {
                name = names[0];
              }
              var curated_display_name = tribute.curated_display_name.value;
              if (curated_display_name != "") {
                name = curated_display_name;
                defaultname = false;
                DateClass = "TribDate TribDateNewLine";
                NameDateClass = "TribNameDate TribNameDatePinned";
                Chim = "";
              }
              if (tribute.sortorder.value != null) {
                pinned = true;
                NameClass = "TribName TribPinned";
              }
              return (
                <div className="TribItem" key={tribute.name.value}>
                  <div className={NameDateClass}>
                <div className={NameClass} key={tribute.name.value}
                  dangerouslySetInnerHTML={{ __html: name }} />{Chim}
                    <div className={DateClass} key={tribute.datetime.value}
                  dangerouslySetInnerHTML={{ __html: tribute.datetime.value.toLocaleDateString('Default', { month: 'long' }) + " " + tribute.datetime.value.toLocaleDateString('Default', { day: 'numeric' }) + ", " + tribute.datetime.value.toLocaleDateString('Default', { year: 'numeric' }) }} />
                  </div>
                  <div className="TribCopy" key={tribute.tribute.value}
                    dangerouslySetInnerHTML={{ __html: tribute.tribute.value }} />
                </div>
          )
            } else {
              return (
                <div className="TribHidden" key={tribute.name.value} ></div>
              )
            }
          })}

        </div>

      );

    } else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default TributeItems;