import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class BillEulogy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>

            <div className="TopImage">
            <canvas id="TopImageCan-Bill" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">

                <div className="articleTop">
<div className="TBDtitle">Eulogy delivered by Bill Gates (Trey)</div>
<div className="article_top_byline"><span className="TBDdate">October 10, 2020</span></div>
</div>
<p>Over the last few weeks, people's expression of love and  comfort have been amazing. And I'll just read from one of those notes that was  written by Warren Buffett. &quot;Like my dad, your father unwaveringly believed  that you had unlimited potential. And again, like my dad, he also felt that  here and there, you needed a little guidance. Finally, he had the wisdom to  know that the steering required would best be accomplished by example rather  than by edicts or preaching.&quot;</p>
<p>That rang very true. Of course, Dad was born in 1925  back in Bremerton. One of his early achievements was to be an Eagle Scout. And that  is something he was very enthused about, set a high standard there. He wanted  me to become an Eagle Scout and I almost got there but I got distracted by  computers and stopped going. So I, in many things, I fell a little bit short  there of his incredible example. </p>
<p>Dad, of course, was tall. He had red hair like his  daughter Libby and his granddaughter Jennifer.</p>
<p>He served in the Army. He never talked much about it but  when we were doing the book tour for his book, I got him explaining about the  time he spent which, fortunately the war ended before he had to engage, so he  was in Japan for a year. </p>
<p>When he came back the GI Bill made it easy for him to go  to college and then law school. He lived on a houseboat. He liked to dance. When  he saw my mom, he said to her, &quot;Hey, do you know any tall girls that I  could dance with?&quot; And Mom stood on her tiptoes and said, &quot;What about  me?&quot; In fact, they served as dance instructors on a cruise ship early in  their marriage. Dad's love of dancing continued. </p>
<p>In fact, he was a regular viewer of <em>Dancing with the Stars</em> and for his 85th birthday we organized a lot  of really amazing dancers to come up and we had judges deciding who the best  dancers were and amazingly Mimi and Dad received the highest score that night.  Very, very impressive.</p>
<p>Soon after I was born the tradition of going to Hood  Canal, going to Cheerio, got started. And that was an amazing thing, where  there were games and traditions. And we met the other families and learned  about the other families. A lot of our growing up took place during those  summer weeks. As Jon mentioned, Dad was the mayor and so that meant that after  every campfire, Dad would decide what order he would go by the houses, and  whoever's house he got to first, you had to get on the porch and go to bed. I  always told Dad, &quot;Hey, because you're leading, can't I just wait and go  back with you?&quot; And he said, &quot;No, when we get to our house, you've got  to go inside and go to bed. So, no special privileges just because you're the  son of the mayor.&quot;</p>
<p>Dad was very hardworking. Even when I would get up for  breakfast, Dad would usually already be gone. He liked to get to work early and  he came home just in time for dinner. He was good about sharing his work. He  talked about these complex cases, including antitrust cases he was working on. He  gave us a real sense that the complex things he was working on, that he thought  we should learn about them and that even our thoughts about them might be  interesting. </p>
<p>They had lots of parties. I'm sure it was good for Dad's  career, but it was also kind of fun. We got to see a lot of adults. We actually  got good at talking to adults, even though that thing about serving coffee or serving  drinks, at the time I know we didn't embrace it enthusiastically. </p>
<p>My dad was very judicial in his approach to things. In  fact, one of his goals was to be a federal judge. There was a time when he had  that opportunity. But unfortunately, it wasn't a good time for him to leave his  law firm so he let that go by. I was a bit disappointed, because it would have  been great to see Dad sitting up there being the judge, wearing those robes. It  would have fit with him very, very well.</p>
<p>There was a tribute recently in the Bard newspaper about how, back in  1970, Dad drove for them to have minority scholarships. So, 50 years ago, he  was already aware that Black lives matter and doing work to reduce inequity. He  was very involved in every civic activity: Planned Parenthood, Municipal League,  United Way, you name it.</p>
<p>Along with my mom, they were showing up a lot.  Tragically in 1994, of course, Mom passed away. Dad was quite down for a while.  And as was mentioned, he decided to change. He decided to become more open and  apologized that a lot of the emotional connection had been through my mother,  which we were stunned that Dad was rethinking and so direct, so appreciative in  a great way.</p>
<p>He had great joy with Mimi. In fact, in his book he says  that his gravestone should read, &quot;He married well,&quot; and among many other  things that would be very appropriate. Early on when Dad and Mimi were  together, we took a trip to China. It&rsquo;s one of the few times I think I got Dad  in trouble because we were in the most beautiful place, Guilin, going down this  river with these beautiful mountains. But I asked Dad to play bridge so he and  Warren Buffett and Bill Gerbinning and I didn't look up the whole day. We sat  there just playing the cards, having a great time and I don't think Mimi expected  he would be so distracted in that wonderful part of the trip. </p>
<p>Mimi helped start a tradition of taking kids on  10-year-old trips and on one of those, the one for Rory, I sort of broke the  rules and came along. I have incredible memories of being in Antarctica with  Dad and Mimi and Rory, one of the best trips ever. </p>
<p>Even though Dad had had this amazing legal career the  next thing he took on was helping to create the Gates Foundation. Along with  Patty Stonesifer, they built the foundation. Melinda and I were mostly doing  other things and they got us off to an amazing start. A lot of our best work, our  vaccine work, our Global Fund work was done in those very early years. He also  set the tone, the tone of integrity, modesty. The Foundation to this day is  really shaped by the values that my father instilled in it. </p>
<p>His book, <em>Showing Up  for Life</em>, was a gift. I was going through it last night, appreciating so  many of the lessons there. In fact, some of you may know, his friends wrote a  book about how he showed up in their life, which was really enlightening to me.  Many stories about things he'd done to help his partners at the law firm, he  had never talked about.</p>
<p>He was still fighting inequity. He wrote a book about  the estate tax with Chuck Collins. His last big political endeavor was not a  success but he tried to get there to be a Washington State income tax in 2010. He  got 36% of the vote, which is not very good, but he knew that someday progressive  taxation would be important.</p>
<p>Of course, in his last 10 years he was dealing with Alzheimer's.  And the doctor said, &quot;Whatever you are, you'll become more like that when  you have Alzheimer's.&quot; Fortunately, in his case, that meant he was even  more polite and friendly than ever.</p>
<p>He was very modest. When we'd have people from the  Foundation come and interview him about things he'd done, they'd say, &quot;Look,  here's this speech you gave and here's this thing you did and he&rsquo;d say,  &quot;No, I don't think I did that. I don't remember that. And that would be  kind of immodest to think that I did that.&quot; So, he denied it all. </p>
<p>He had a few years where his experience of growing up in  the Depression made him think, &quot;Okay, do I have enough resources?&quot; He  would be calling Kristi almost every day trying to go through, &quot;Well, I  don't think I have enough money.&quot; Believe me, he did have enough money. So,  Kristi would make that case in various ways. Eventually that didn't worry him so  much. But even just a few months ago when we'd had a very fancy meal, at the  end of the meal he kind of looked around, like, &quot;Okay, who's going to pay  for this meal? Is it my turn to pay or not?&quot; </p>
<p>He swore more in the last 10 years than he had before but  it was almost entirely to himself. If he'd spill or if he'd said something  wrong that upset him. </p>
<p>He was amazing, even in those last 10 years. </p>
<p>He was an amazing person. All of us here benefited from  his love and his incredible example.  </p>
                <br/><br/>
                </div>
            </div>
        );
    }
}

export default BillEulogy;