import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let unsubscribeSubject = new Subject();

class FooterLockupRender extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    fetchMainPage() {
        client.items()
            .type('footer_lockup')
            .depthParameter(2)
            .toObservable()
            .pipe(takeUntil(unsubscribeSubject))
            .subscribe(response => {
                //console.log(response.items);
                //document.getElementById('main_body').innerHTML = response.items[0].main_body.resolveHtml();
                this.setState({
                    loaded: true,
                    footer_lockup: response.items
                });
            });
    }
    unsubscribe() {
        unsubscribeSubject.next();
        unsubscribeSubject.complete();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount() {
        this.fetchMainPage();
    }
    componentDidUpdate() {
    }

    render() {
        if (this.state.loaded) {
            return (
                <div>



                    {this.state.footer_lockup.map((page) => {
                        return (
                            <div className="Footer" data-heroimage={page.footer_image.value[0].url} key={page.system.name}>
                                <img className="footer_lockup_image" src={page.footer_image.value[0].url}></img>
                                <div className="FooterBase">
                                    <div id="copywrite" className="CW" key={page.copywrite.value}
                                        dangerouslySetInnerHTML={{ __html: page.copywrite.value }} />
                                    <div className="FooterR">
                                        <div id="privacy_link" className="PP" key={page.privacy_link.value}
                                            dangerouslySetInnerHTML={{ __html: page.privacy_link.value }} />
                                        <div id="terms_link" className="TOU" key={page.terms_link.value}
                                            dangerouslySetInnerHTML={{ __html: page.terms_link.value }} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            );
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}

export default FooterLockupRender;