
var KontentDelivery = require('@kentico/kontent-delivery');

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 
 */
export class InlineQuoteMod extends KontentDelivery.ContentItem {
        constructor() {
        super({
            richTextResolver: InlineQuoteMod => {
                let InlineQuoteModCopy = InlineQuoteMod.inlineQuoteCopy.value;
                let InlineQuoteModBy = InlineQuoteMod.inlineQuoteBy.value;
              return `<div class='InlineQuoteHolder'><div class='InlineQuoteModCopy'>${InlineQuoteModCopy}</div><div class='InlineQuoteModBy'>- ${InlineQuoteModBy}</div></div>`;
            },
            propertyResolver: ((elementName) => {
                if (elementName === 'inline_quote_copy') {
                    return 'inlineQuoteCopy';
                }
                if (elementName === 'inline_quote_by') {
                    return 'inlineQuoteBy';
                }
                return elementName;
            })
        });
    }
}
