
import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                <div className="main_body">
                <p><strong>Privacy Policy</strong></p>
                <p><strong>Effective Date: 10/12/2020</strong></p>

                <p>The Gates Notes LLC (“<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”) is committed to protecting your privacy. This Privacy Policy (“<strong>Policy</strong>”) describes how we collect, use, store, process, and share your information in relation to the William H. Gates II Memorial website (<a href="https://www.giftsofalife.family">https://www.giftsofalife.family</a>) (the “<strong>Site</strong>”). By accessing, browsing, or using the Site, you expressly consent to the collection, use, storage, processing, and disclosure of your information, including personally identifiable information, as described in this Policy. IF YOU DO NOT AGREE WITH THIS POLICY, DO NOT ACCESS OR USE THE SITE. This Policy is part of and incorporated into the <a href="/terms-of-use">Terms of Use</a> governing your use of the Site. Parts of the Terms of Use affect this Policy, so please be sure to review the Terms of Use prior to using the Site. Capitalized terms used but not defined herein have the definitions set forth in the Terms of Use.</p>
                <p><strong>Please be aware that the Site and all associated services and systems are housed on servers in the United States. If you are located outside of the United States, information we collect (including cookies) are processed and stored in the United States, which may not offer the same level of privacy protection as the country where you reside or are a citizen. By using the site and providing information to us, you consent to the transfer to and processing of the information in the United States.</strong></p>
<ol type="1">
  <li><strong>Changes to this Policy.</strong>
  <p>We reserve the right to change this Policy at any time, in our sole discretion, upon notice to you. We may give notice by posting the updated Policy on the Site, sending you an email, or by any other reasonable means. You should periodically review this Policy for changes and you can review the most current Policy at any time at: <a href="https://www.giftsofalife.family/privacy-policy">https://www.giftsofalife.family/privacy-policy</a>. The updated Policy will govern your use of the Site as of the Effective Date indicated at the top of this Policy. If you do not agree to the updated Policy, you should stop using the Site. Your use of the Site after the Effective Date of the updated version of this Policy will constitute your acceptance of the updated Policy.</p></li>
  <li><strong>Information collection.</strong><br/><br/>
  <ol type="A">
  <li><strong>General.</strong> When you visit the Site, you can browse without submitting any personal information about yourself. We will, however, receive and store some non-personally identifiable information about your visit, as described below. In order to participate in certain functionalities, you may be asked to provide personally identifiable information. Personally identifiable information is information that can identify you, including, for example your name or email address. It will always be your choice, or the choice of anyone acting for you, to provide personally identifiable information.<br/><br/></li>
  <li><strong>User content and user discussions.</strong> The Site may allow you to submit comments or other information (“<strong>User Content</strong>”) to interactive portions of the Site, including, but not limited to, the tribute submission form.<br/><br/></li>
  <li><strong>Email address collection.</strong> In order to communicate with you regarding your tribute, you may be required to submit your name and email address to us.<br/><br/></li>
  <li><strong>Tributes submitted by you.</strong> The Site provides users the option to submit a tribute in memory of William H. Gates II by filling out a form available on the Site. This form asks for your name and email address, and provides a comment box for you to write your own tribute. Any communications and contact information that you voluntarily submit to us may be saved indefinitely and in a profile specific to you. Your name and tribute may be posted publicly on the website for other users to see. Your email address will not be posted publicly, but we may use your name and email address to communicate with you about your tribute. Your tribute submission is subject to our Terms of Use.<br/><br/></li>
  <li><strong>Automatically collected information.</strong> Similar to other websites, we may collect some information automatically and store it in log files. This collection may include, but is not limited to: your domain name and host for Internet access, the Internet address of the site from which you came, the date and time of your access, your computer’s IP address and information about its operating system, browser, and host, the date and time you access the Site and the pages you visit. We collect this non-identifying information in order to help diagnose problems with our server and to administer the Site. We also use it to gather broad demographic information. 
<br/><br/>We may automatically collect information using the following mechanisms:
<br/><br/><em>Cookies.</em> A cookie is a small text file that is stored on a computer for record-keeping purposes. Our third-party analytics providers use cookies to record information about your activities on the Site and to “remember” you when you return to the Site. Some cookies remain on your computer until you delete them. Others, like session ID cookies, expire when you close your browser. You may set your browser setting to attempt to reject cookies and you may still use the Site, however certain features of the Site may not be available if your browser does not accept cookies. See the section “‘Do not track’ settings” below for more information on how the Services may respond to your browser settings. We do not control the use of cookies by third parties. Also see the section on “Third party analytics providers” below.
<br/><br/><em>Pixels (aka web beacons/web bugs/java script).</em> Pixels are tiny graphics with a unique identifier that are used to track the online movements of web users. Unlike cookies, which are stored on a computer’s hard drive, pixels are small graphics that are about the size of the period at the end of the sentence that are embedded invisibly on web pages or in HTML-based emails. Our third-party analytics providers may place pixels on the Site that track what other websites you visit (both before and after visiting the Site). Our third-party analytics providers use information obtained from pixels to help us improve our business and the Site. We do not control the use of pixels by third parties. See the section on “Third party analytics providers” below.<br/><br/></li>
  <li><strong>Third party analytics providers.</strong> We use third parties to help us operate and improve the Site. We may provide these third parties with information we collect and they may collect information from you about your use of the Site. If we provide information to third parties, it will, unless specifically noted otherwise in this Policy, be governed by this Policy and may only be used by those third parties to help us operate or improve our business or the Site and to provide services to us. We do not control information independently collected by third parties and are not responsible for their use of that information.<br/><br/></li>
  </ol>
  </li>
  <li><strong>Information use.</strong><br/><br/>
  <ol type="A">
  <li><strong>Use of personally identifiable information.</strong> We may use your personally identifiable information, such as your email address, to communicate with you about the Site. We may also use personally identifiable information to resolve disputes; to detect and protect against errors, fraud, and criminal activity; to assist law enforcement; to enforce this Policy and the Terms of Use; or for any other purpose described in this Policy or that we describe to you at the time of collection.<br/><br/></li>
  <li><strong>Use of non-personally identifiable information.</strong> We may use non-personally identifiable information for any lawful purpose, including, but not limited to analyzing trends, administering the Site, tracking users’ movements on the Site, and for management and improvement of the Site. We may also use this information to monitor aggregate metrics such as total number of visitors or pages viewed; or to track submissions in any interactive portion of the Site. We may also combine non-personally identifiable information with non-personally identifiable information collected from other sources.<br/><br/></li>
  <li><strong>Information retention.</strong> We will retain your information indefinitely, or as long as legally required or allowed. We may dispose of any information in our discretion without notice, subject to applicable law. We do not undertake any retention obligations through this statement.<br/><br/></li>
  </ol>
  </li>
  <li>
  <strong>Information disclosure.</strong><br/><br/>
  <ol type="A">
  <li><strong>Affiliated entities or service providers.</strong> We will not sell or rent any of your personal information to third parties for marketing purposes. However, we may disclose your information to any affiliated entity or organization and to agents and service providers. These third parties may include various business service providers or providers that supply support for the Site or otherwise are working directly with us. Use of information by affiliated entities and organizations will be subject to this Policy or an agreement that is at least as restrictive as this Policy. For information on the use of information by agents and service providers, also see the “Third Party Analytics Providers” Section above.<br/><br/></li>
  <li><strong>User content.</strong> If you submit User Content to any interactive portion of the Site, including, but not limited to, the tribute submission section of the Site, your User Content will be made publicly available to anyone who visits that area of the Site. Other users or the general public may access, re-post, or use User Content that you make publicly available through the Site. Even if you remove or delete your User Content, copies may remain in cached or archived areas of the Site. If you share User Content with Linked Sites, the content you share will be subject to that site’s privacy policy.<br/><br/></li>
  <li><strong>Legal requirements.</strong> In some cases, we may disclose your information (1) as required by any applicable law or legal regulation; (2) if we believe disclosure is needed to protect our rights, property, or safety; (3) to government regulators or law enforcement authorities in connection with any judicial proceeding, court order, subpoena, or legal process served on us or the Site; and (4) to respond to a physical threat to you or another person.<br/><br/></li>
  <li><strong>Insolvency and business transitions.</strong> If we should ever file for bankruptcy or engage in a business transition such as a merger with another company, or if we purchase, sell, or reorganize all or part of our business or assets, we may disclose your information, including personal information, to prospective or actual purchasers in connection with one of these transactions.<br/><br/></li>
  <li><strong>Disclaimer.</strong> We cannot ensure that all of your information will never be disclosed in ways not otherwise described in this Policy. For example, a third party may unlawfully intercept or access transmissions or private communications, or other users of the Site may abuse or misuse your personal information. No transmission of data over the internet can be 100% secure.<br/><br/></li>
  </ol>
  </li>
  <li><strong>Information security measures.</strong>
  <p>Keeping your information secure is of great concern to us. We exercise care in facilitating the transmission of information between your device or computer and our servers (or the third-party servers that operate and store information for the Site). Personally identifiable information collected by the Site is stored in operating environments with restricted access and are not available to the public. While we have mechanisms in place to safeguard your personal information after we receive it, no transmission of data over the internet can be guaranteed to be 100% secure.</p></li>
  <li><strong>“Do not track” settings.</strong>
  <p>Most browsers can be set to disable existing cookies, to automatically refuse cookies, or to notify you when you receive a cookie so you can choose whether or not to accept it. Please note that doing so may negatively impact your user experience on the Site, as some features may not work properly when cookies are disabled. Depending on your mobile device and operating system, you may not be able to delete or block all cookies. You may also set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our email and performed certain functions with it. Although we do our best to honor the privacy preferences of our users, we are unable to respond to “Do not Track” signals from your browser at this time.</p></li>
  <li><strong>Children’s privacy.</strong><br/><br/>
    <ol type="A">
  <li><strong>This Site is not for or directed towards children.</strong> Our primary audience is adults and young adults. We do not intend to and will not knowingly collect any personal information from children under the age of 13; children under the age of 13 are prohibited from using the Site without parent/guardian supervision. If we learn that we have collected information from a child under the age of 13, we will remove that information immediately and delete it from our servers (subject to applicable law and this Policy). If you believe User Content from a child under the age of 13 has been posted to the Site, please notify us at <a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>. We also recommend that children over the age of 13 ask their parents for permission before sending any information about themselves to anyone over the internet.<br/><br/></li>
  <li><strong>California minors.</strong> If you are a California resident who is under age 18 and you are unable to remove publicly-available User Content that you have submitted to us, you may request removal by contacting us at: <a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>. When requesting removal, you must be specific about the information you want removed and provide us with specific information, such as the URL for each page where the information is located, so that we can find it. We are not required to remove any content or information that: (1) federal or state law requires us or a third party to maintain; (2) was not posted by you; (3) is anonymized so that you cannot be identified; (4) you do not follow our instructions for removing or requesting removal; or (5) you received compensation or other consideration for providing the Content or information. Removal of your content or information from the site does not ensure complete or comprehensive removal of that content or information from our systems or the systems of our service providers. We are not required to delete the Content or information posted by you; our obligations under California law are satisfied so long as we anonymize the Content or information or render it invisible to other users and the public.<br/><br/></li>
  <li><strong>California Shine the Light law.</strong> California Civil Code Section 1798.83 permits California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed personal information (as defined under that statute) of that California resident, for direct marketing purposes in the preceding calendar year and the categories of that kind of personal information that was disclosed to them. If you are a California resident and you wish to make such a request, you may do so by contacting us as specified in Section 11 below.<br/><br/></li>
  <li><strong>Linked Sites.</strong> The Site contains links to other sites (“<strong>Linked Sites</strong>”). We have no control over, and are not liable or responsible for the privacy practices, terms of use, or content of Linked Sites. The Linked Sites may collect personally identifiable information from you that is not subject to our control. The data collection practices of Linked Sites will be governed by that site's privacy policy. Please review the privacy policies of any Linked Sites you may access.<br/><br/></li>
    </ol>
  </li>
  <li><strong>Accessing, updating, or deleting your personal information.</strong>
If personal information that you have submitted to us changes or if you would like to remove your personal information, please contact us via email at <a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>. We will make good faith efforts to resolve requests to remove information or correct inaccurate information except where the request is unreasonable, requires disproportionate technical effort or expense, jeopardizes the privacy of others, or would be impractical.<br/><br/></li>
  <li><strong>Contact information and privacy questions.</strong>
If you have questions about this Policy or other matters that relate to it, you may contact us via email at <a href="mailto:team@gatesnotes.com">team@gatesnotes.com</a>.<br/><br/></li>
  </ol>
                    </div>
            </div>
        );
    }
}

export default PrivacyPolicy;