var KontentDelivery = require('@kentico/kontent-delivery');

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 
 */
export class InlineGalleryImage extends KontentDelivery.ContentItem {
        constructor() {
        super({
            richTextResolver: InlineGalleryImage => {
                let InlineGalleryImagegalleryimage = InlineGalleryImage.inlineImagegalleryimage.value[0].url;
                let InlineGalleryImageimage_title = InlineGalleryImage.inlineImageimage_title.value;
                let inlineImageimage_caption = InlineGalleryImage.inlineImageimage_caption.value;
              return `<div class="carousel-bar" data-slide-to=""></div>
              <div class="item" data-slide-num="">
              <canvas class='carousel-image' style='background-image: url(${InlineGalleryImagegalleryimage});'></canvas>
              <div class="carousel-caption">
                <h3>${InlineGalleryImageimage_title}</h3>
                <p>${inlineImageimage_caption}</p>
              </div>`;
            },
            propertyResolver: ((elementName) => {
                if (elementName === 'galleryimage') {
                    return 'inlineImagegalleryimage';
                }
                if (elementName === 'image_title') {
                    return 'inlineImageimage_title';
                }
                if (elementName === 'image_caption') {
                    return 'inlineImageimage_caption';
                }
                return elementName;
            })
        });
    }
}
