import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
 
const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '6dd29c3c-ef56-4c00-822d-a48187eb5251',
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
 
// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.
 
class MyAIComponent extends React.Component {
  
}
 
export default withAITracking(reactPlugin, MyAIComponent);
export const appInsightsVar = appInsights;