import React, { Component } from 'react';
import { client } from './config';
import { managementclient } from './config';
import moment from 'moment';
import { validate } from 'validate.js';
import { appInsightsVar } from './AppInsights';

let itemID = '';
let FormName = '';
let FormDate = '';
let date = "";
let formState = "off";
let nameValid = false;
let emailValid = false;
let tributeValid = false;
let AgreeValid = false;

class TributeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      Form: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleTributeChange = this.handleTributeChange.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
  }



  fetchForm(slug) {
    client.items()
      .type('tributes_form')
      .elementsParameter(['name_field_copy', 'email_field_copy', 'comment_field_copy', 'name_field_placeholder', 'email_field_placeholder', 'comment_field_placeholder', 'agree_field_copy', 'submit_field_copy', 'form_title', 'form_description'])
      .depthParameter(1)
      .toObservable()
      .subscribe((response) => {
        //console.log(response);
        this.setState({
          loaded: true,
          Form: response.items[0]
        });
      });
  }

  componentDidMount() {
    let slug = this.props.match.params.slug;
    this.fetchForm(slug);
  }
  
  componentDidUpdate() {
    var type = window.location.hash.substr(1);
    if (type === "Tributes") {
      this.scrollToTributesTF();
    }
  }
  scrollToTributesTF(e) {
    var element = document.getElementById("TheTributeForm");
    if (element != null) {
      setTimeout(function (){
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000);
    } else {
      window.location.href = '/#Tributes';
    }
  }
  submitMC() {
    managementclient.addContentItem()
      .withData(
        {
          name: itemID,
          codename: itemID,
          type: {
            codename: 'modular_tribute'
          },
          external_id: itemID
        }
      )
      .toObservable()
      .subscribe((response) => {
        //console.log(response);

        ////
        managementclient.upsertLanguageVariant()
          .byItemExternalId(itemID)
          // .byItemId('f4b3fc05-e988-4dae-9ac1-a94aba566474')
          //.byItemCodename('mt3')
          .byLanguageCodename('default')
          .withElements([
            {
              element: {
                codename: 'name'
              },
              value: '<p>' + this.state.name + '</p>'
            },
            {
              element: {
                codename: 'email'
              },
              value: '<p>' + this.state.email + '</p>'
            },
            {
              element: {
                codename: 'tribute'
              },
              value: '<p>' + this.state.tribute + '</p>'
            },
            {
              element: {
                codename: 'datetime'
              },
              value: this.state.date
            },
            {
              element: {
                codename: 'moderation'
              },
              value:
                [
                  {
                    codename: "pending"
                  }
                ]
            }
          ]
          )
          .toObservable()
          .subscribe((response) => {
            //console.log("item update:" + response.debug.response.status);
/*             if (response.debug.response.status === '200' || response.debug.response.status === '201') {
              var element = document.getElementById("TheTributeForm");
              //element.classList.add("TributeFormDone");
            } */
          },
            (error) => {
              console.log(error);
            });
        ////

      },
        (error) => {
          console.log(error);
        });
  }
  submit_giftsoflife() {
    var modtrib = 
            {
    email:this.state.email,
    tribute:this.state.tribute,
    name:this.state.name,
            }
            ;
    fetch('https://api.giftsofalife.family/api/stmgmt', {
    method:'POST',
    headers: {
    'Accept':'application/json',
    'Content-Type':'application/json',
      },
    body:JSON.stringify({
    itemName:itemID,
    mt:modtrib
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      var elementTFT = document.getElementById("TributeFormTop");
      elementTFT.style.opacity = ".2";
      elementTFT.style.pointerEvents = "none";
      var element = document.getElementById("TributeFormTopBottom");
      element.style.opacity = ".2";
      element.style.pointerEvents = "none";
      var elementTY = document.getElementById("TributeFormThankYou");
      elementTY.classList.remove("Hidden");
      var elementTSE = document.getElementById("TributeSubmitError");
      elementTSE.classList.add("Hidden");
      appInsightsVar.trackEvent({name: "SubmitGOL", properties: { value: "Success" }});
    })
    .catch((error) => {
      console.error('Error:', error);
      appInsightsVar.trackEvent({name: "SubmitGOL", properties: { value: "Error" }});
      this.submit_gvseark();
    });
    appInsightsVar.flush();
  }
  submit_gvseark() {
    var modtrib = 
            {
    email:this.state.email,
    tribute:this.state.tribute,
    name:this.state.name,
            }
            ;
    fetch('https://gvseark.azurewebsites.net/api/stmgmt', {
    method:'POST',
    headers: {
    'Accept':'application/json',
    'Content-Type':'application/json',
      },
    body:JSON.stringify({
    itemName:itemID,
    mt:modtrib
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      var elementTFT = document.getElementById("TributeFormTop");
      elementTFT.style.opacity = ".2";
      elementTFT.style.pointerEvents = "none";
      var element = document.getElementById("TributeFormTopBottom");
      element.style.opacity = ".2";
      element.style.pointerEvents = "none";
      var elementTY = document.getElementById("TributeFormThankYou");
      elementTY.classList.remove("Hidden");
      var elementTSE = document.getElementById("TributeSubmitError");
      elementTSE.classList.add("Hidden");
      appInsightsVar.trackEvent({name: "SubmitGVS", properties: { value: "Success" }});
    })
    .catch((error) => {
      console.error('Error:', error);
      var elementTFT = document.getElementById("TributeFormTop");
      elementTFT.style.opacity = "1";
      elementTFT.style.pointerEvents = "all";
      var element = document.getElementById("TributeFormTopBottom");
      element.style.opacity = "1";
      element.style.pointerEvents = "all";
      var elementTY = document.getElementById("TributeFormThankYou");
      elementTY.classList.add("Hidden");
      var elementTSE = document.getElementById("TributeSubmitError");
      elementTSE.classList.remove("Hidden");
      appInsightsVar.trackEvent({name: "SubmitGVS", properties: { value: "Error" }});
    });
    appInsightsVar.flush();
  }
  handleSubmit(e) {
    var elementTFT = document.getElementById("TributeFormTop");
    elementTFT.style.opacity = ".2";
    elementTFT.style.pointerEvents = "none";
    var element = document.getElementById("TributeFormTopBottom");
    element.style.opacity = ".2";
    element.style.pointerEvents = "none";

    //console.log('Name: ' + this.state.name);
    //console.log("EMail: " + this.state.email);
    //console.log("Tribute: " + this.state.tribute);
    //console.log("Agree: " + this.state.agree);
    itemID = FormName + FormDate;
    itemID = itemID.replace(/ /g, "_").replace(/-/g, "_").replace(/:/g, "_").toLowerCase();
    if (!this.state.agree || this.state.name === undefined || this.state.email === undefined || this.state.tribute === undefined) {
      //var element = document.getElementById("TheTributeForm");
      //element.classList.add("TributeFormError");
    appInsightsVar.trackEvent({name: "SubmitClick", properties: { value: "FormIssue" }});
      e.preventDefault();
    } else {
      //var element = document.getElementById("TheTributeForm");
      //element.classList.remove("TributeFormError");
    appInsightsVar.trackEvent({name: "SubmitClick", properties: { value: "Processed" }});

      this.submit_giftsoflife();
      //this.submitMC();
    }
    appInsightsVar.flush();
    e.preventDefault();
  }
  checkValid() {
    var element = document.getElementById("submitButton");
    var elementAF = document.getElementById("agree");
    var elementEFI = document.getElementById("email");
    if (nameValid && emailValid && tributeValid && AgreeValid) {
      element.classList.add("SubmitOn");
      elementEFI.style.color = "white";
      elementAF.style.outline = "0px solid white";
    } else {
      element.classList.remove("SubmitOn");
      if (nameValid && tributeValid && AgreeValid) {
        elementEFI.style.color = "#ff4f4f";
      }
      if (nameValid && tributeValid && emailValid) {
        elementAF.style.outline = "2px solid #ff4f4f";
      }
    }
  }
  handleNameChange(e) {
    this.setState({ name: e.target.value });
    FormName = e.target.value;
    var elementNF = document.getElementById("NameField");
    if (FormName === "" || FormName === " " || FormName === undefined) {
      if (nameValid) {
        appInsightsVar.trackEvent({name: "NameField", properties: { value: "Emptied" }});
      }
      nameValid = false;
      elementNF.classList.remove("FullOpacity");
    } else {
      if (!nameValid) {
        appInsightsVar.trackEvent({name: "NameField", properties: { value: "Filled" }});
      }
      nameValid = true;
      elementNF.classList.add("FullOpacity");
    }
    appInsightsVar.flush();
    this.checkValid();
  }
  handleEmailChange(e) {
    const validationResult = validate.single(e.target.value, { presence: true, email: true });//validate(e.target.value, constraints);
    //console.log("Validation Result:" + validationResult);
    var elementEF = document.getElementById("EmailField");
    if (validationResult === undefined) {
      if (!emailValid) {
        appInsightsVar.trackEvent({name: "EmailField", properties: { value: "Valid" }});
      }
      this.setState({ email: e.target.value });
      var element = document.getElementById("TributeFormEmailError");
      element.innerHTML = "";
      emailValid = true;
      elementEF.classList.add("FullOpacity");
    } else {
      if (emailValid) {
        appInsightsVar.trackEvent({name: "EmailField", properties: { value: "NotValid" }});
      }
      var elementTFEE = document.getElementById("TributeFormEmailError");
      elementTFEE.innerHTML = validationResult;
      emailValid = false;
      elementEF.classList.remove("FullOpacity");
    }
    appInsightsVar.flush();
    this.checkValid();
  }
  handleTributeChange(e) {
    this.setState({ tribute: e.target.value });
    var Tribute = e.target.value;
    var elementTF = document.getElementById("TributeField");
    if (Tribute === "" || Tribute === " " || Tribute === undefined) {
      if (tributeValid) {
        appInsightsVar.trackEvent({name: "TributeField", properties: { value: "Emptied" }});
      }
      tributeValid = false;
      elementTF.classList.remove("FullOpacity");
    } else {
      if (!tributeValid) {
        appInsightsVar.trackEvent({name: "TributeField", properties: { value: "Filled" }});
      }
      tributeValid = true;
      elementTF.classList.add("FullOpacity");
    }
    appInsightsVar.flush();
    this.checkValid();
  }
  handleAgreeChange(e) {
    this.setState({ agree: e.target.checked });
    date = moment()
      .utcOffset('-07:00')
      .format('YYYY-MM-DD hh:mm:ss a');
    this.setState({ date: date });
    FormDate = date;
    if (e.target.checked) {
      if (!AgreeValid) {
        appInsightsVar.trackEvent({name: "Check Accept", properties: { value: "On" }});
      }
      AgreeValid = true;
    } else {
      if (AgreeValid) {
        appInsightsVar.trackEvent({name: "Check Accept", properties: { value: "Off" }});
      }
      AgreeValid = false;
    }
    appInsightsVar.flush();
    this.checkValid();
  }
  toggleForm(e) {
    var element = document.getElementById("TributeFormTopBottom");
    var element2 = document.getElementById("TributeFormTopR");
    if (formState === "off") {
      element.classList.remove("Hidden");
      element2.innerHTML = "˄";
      formState = "on";
      appInsightsVar.trackEvent({name: "Toggle Form", properties: { value: "Open" }});
    } else {
      element.classList.add("Hidden");
      element2.innerHTML = "˅";
      formState = "off";
      appInsightsVar.trackEvent({name: "Toggle Form", properties: { value: "Closed" }});
    }
    appInsightsVar.flush();
  }
  render = (props) => {
    if (this.state.loaded) {
      const Form = this.state.Form;
      const name_field_copy = Form.name_field_copy;
      const email_field_copy = Form.email_field_copy;
      const comment_field_copy = Form.comment_field_copy;
      const agree_field_copy = Form.agree_field_copy;
      const submit_field_copy = Form.submit_field_copy;


      return (
        <form onSubmit={this.handleSubmit}>
          <div className="TributeForm" id="TheTributeForm">
            <div id="TributeFormTop" className="TributeFormTop">
              <div className="TributeFormTopL">
                <div className="TributeFormTitle"
                  dangerouslySetInnerHTML={{ __html: Form.form_title.value }} />
                <div className="TributeFormDescription"
                  dangerouslySetInnerHTML={{ __html: Form.form_description.value }} />
              </div>
              <div id="TributeFormTopR" className="TributeFormTopR" onClick={this.toggleForm}>
                ˅
          </div>
            </div>
            <div id="TributeFormTopBottom" className="TributeFormTopBottom Hidden">
              <div className="TributeFormR1">
                <div id="NameField" className='TributeForm50'>
                  <div className='TributeFormLabel'
                    dangerouslySetInnerHTML={{ __html: name_field_copy.value }} />
                  <input type="text" placeholder={Form.name_field_placeholder.value} maxLength="30" id="name" defaultValue={this.state.name} onChange={this.handleNameChange}></input>
                </div>
                <div id="EmailField" className='TributeForm50'>
                  <div className='TributeFormLabel'
                    dangerouslySetInnerHTML={{ __html: email_field_copy.value }} />
                  <input type="text" placeholder={Form.email_field_placeholder.value} maxLength="30" id="email" defaultValue={this.state.email} onChange={this.handleEmailChange}></input>
                  <div id="TributeFormEmailError" className="TributeFormEmailError"></div>
                </div>
              </div>
              <div id="TributeField" className="TributeFormR2">
                <div className='TributeFormLabel'
                  dangerouslySetInnerHTML={{ __html: comment_field_copy.value }} />
                <textarea cols="60" rows="5" name={comment_field_copy.value} maxLength="3000" placeholder={Form.comment_field_placeholder.value} id="tribute" defaultValue={this.state.tribute} onChange={this.handleTributeChange}></textarea>
              </div>
              <div className="TributeFormR3">
                <input type="checkbox" id="agree" value={this.state.agree} onChange={this.handleAgreeChange}></input><span className={agree_field_copy.value}
                  dangerouslySetInnerHTML={{ __html: agree_field_copy.value }} />
                <input id="submitButton" type="submit" placeholder={submit_field_copy.value}></input>
              </div>
              <div id="TributeSubmitError" className="TributeSubmitError Hidden">Unable to process data, please try again at a later time</div>
            </div>
            <div id="TributeFormThankYou" className="TributeFormThankYou Hidden">Thank you!</div>
          </div>
        </form>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default TributeForm;