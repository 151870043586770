import React from 'react';
import { withRouter } from 'react-router-dom';

const RichTextElement = props => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.element.resolveHtml() }}
    />
  );
};

export default withRouter(RichTextElement);
