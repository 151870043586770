import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Eulogies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Eulogies" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">
                    <div className="EulogiesFlex">
                        <div className="EulogyBox" id="EBM">
                            <div className="EulogyBoxInner">
                                <div className="EulogyBoxName">
                                    Mimi Gates
                                </div>
                                <div className="EulogyBoxDesc">
                                    In my mind, the singular quality that best characterizes Bill and his life is his compassion. His enormous capacity to think beyond himself and focus on the well-being of others.
                                </div>
                                <a href="/mimi-eulogy"><div className="EulogyBoxButton">
                                    Read full eulogy
                                </div></a>
                            </div>
                        </div>
                        <div className="EulogyBox" id="EBL">
                            <div className="EulogyBoxInner">
                                <div className="EulogyBoxName">
                                    Libby MacPhee
                                </div>
                                <div className="EulogyBoxDesc">
                                    It's an awesome task to try to sum up the life of a figure as legendary as Dad in a few minutes.
                                </div>
                                <a href="/libby-eulogy"><div className="EulogyBoxButton">
                                    Read full eulogy
                                </div></a>
                            </div>
                        </div>
                        <div className="EulogyBox" id="EBB">
                            <div className="EulogyBoxInner">
                                <div className="EulogyBoxName">
                                Bill Gates (Trey)
                                </div>
                                <div className="EulogyBoxDesc">
                                    He was an amazing person. All of us here benefited from his love and his incredible example.
                                </div>
                                <a href="/bill-eulogy"><div className="EulogyBoxButton">
                                    Read full eulogy
                                </div></a>
                            </div>
                        </div>
                        <div className="EulogyBox" id="EBJ">
                            <div className="EulogyBoxInner">
                                <div className="EulogyBoxName">
                                    Jonathan Roberts
                                </div>
                                <div className="EulogyBoxDesc">
                                    Bill is my foremost role model for what it is to be a human, a man, a father, a husband, a friend, and a citizen.
                                </div>
                                <a href="/jonathan-eulogy"><div className="EulogyBoxButton">
                                    Read full eulogy
                                </div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Eulogies;