
var KontentDelivery = require('@kentico/kontent-delivery');

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 
 */
export class InlineWideImage extends KontentDelivery.ContentItem {
        constructor() {
        super({
            richTextResolver: InlineWideImage => {
                let inlineImage = InlineWideImage.inlineImage.value[0].url;
              return `<div class='inlineImageHolder'><img class='inlineImage' src=${inlineImage} /></div>`;
            },
            propertyResolver: ((elementName) => {
                if (elementName === 'inline_image') {
                    return 'inlineImage';
                }
                return elementName;
            })
        });
    }
}
