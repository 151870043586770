import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let unsubscribeSubject = new Subject();

class HeroLockupRender extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    fetchMainPage() {
        client.items()
            .type('hero_lockup')
            .depthParameter(2)
            .toObservable()
            .pipe(takeUntil(unsubscribeSubject))
            .subscribe(response => {
                //console.log(response.items);
                //document.getElementById('main_body').innerHTML = response.items[0].main_body.resolveHtml();
                this.setState({
                    loaded: true,
                    hero_lockup: response.items
                });
            });
    }
    unsubscribe() {
        unsubscribeSubject.next();
        unsubscribeSubject.complete();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount() {
        this.fetchMainPage();
    }
    componentDidUpdate() {
    }

    render() {
        if (this.state.loaded) {
            return (
                <div>



                    {this.state.hero_lockup.map((page) => {
                        return (
                            <div className="hero_lockup" data-heroimage={page.heroimage.value[0].url} key={page.system.name}>
                                <img className="hero_lockup_image" src={page.heroimage.value[0].url}></img>
                                <img className="hero_lockup_imageM" src={page.heroimagem.value[0].url}></img>
                                <div className="HeroTitle">
                                    <div className="HeroTitleInner">
                                        <div id="title01" className="title01" key={page.title01.value}
                                            dangerouslySetInnerHTML={{ __html: page.title01.value }} />
                                        <div id="title02" className="title02" key={page.title02.value}
                                            dangerouslySetInnerHTML={{ __html: page.title02.value }} />
                                        <div id="title03" className="title03" key={page.title03.value}
                                            dangerouslySetInnerHTML={{ __html: page.title03.value }} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            );
        } else {
            return (
                <div className="SiteLoading">
                    Loading...
                </div>
            )
        }
    }
}

export default HeroLockupRender;