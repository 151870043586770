var KontentDelivery = require('@kentico/kontent-delivery');

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 
 */
export class InlineImageGallery extends KontentDelivery.ContentItem {
        constructor() {
        super({
            richTextResolver: InlineImageGallery => {
                let InlineImageGalleryImages = InlineImageGallery.inlineGalleryImages.value;
              return `<div id="mycarousel" class="carousel slide" data-ride="carousel">
              <div class="carousel-indicators"></div>
              <div class="carousel-inner-saved"></div>
              <div class="carousel-inner">${InlineImageGalleryImages}</div>
              <div class="left carousel-control"  data-slide="prev">
              <span class="chevron-left"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                   viewBox="0 0 158.2 240" style="enable-background:new 0 0 158.2 240;" xml:space="preserve">
              <style type="text/css">
                  .st0{fill:#FFFFFF;}
              </style>
              <g>
                  <path class="st0" d="M118.9,240c13-13.8,25.9-27.6,39.3-41.9c-25-24.9-51.4-51.4-78.2-78.1c26.8-26.8,53.2-53.2,78.3-78.2
                      c-13.4-14.2-26.3-28-39.3-41.8c-0.3,0-0.7,0-1,0c-1.1,1.4-2.1,2.9-3.4,4.2C77.7,41.3,40.9,78.5,4.1,115.7C2.9,117,1.6,118.3,0,120
                      c1.3,1.4,2.5,2.7,3.8,4c36.8,37.2,73.6,74.3,110.4,111.5c1.4,1.4,2.5,3,3.7,4.5C118.2,240,118.5,240,118.9,240z"/>
              </g>
              </svg>
              </span>
            </div>
            <div class="right carousel-control" data-slide="next">
              <span class="chevron-right"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 158.2 240" style="enable-background:new 0 0 158.2 240;" xml:space="preserve">
         <style type="text/css">
             .st0{fill:#FFFFFF;}
         </style>
         <g>
             <path class="st0" d="M39.3,240c-13-13.8-25.9-27.6-39.3-41.9c25-24.9,51.4-51.4,78.2-78.1C51.5,93.2,25.1,66.8,0,41.8
                 C13.4,27.6,26.3,13.8,39.3,0c0.3,0,0.7,0,1,0c1.1,1.4,2.1,2.9,3.4,4.2c36.8,37.2,73.6,74.4,110.4,111.5c1.3,1.3,2.5,2.6,4.1,4.3
                 c-1.3,1.4-2.5,2.7-3.8,4c-36.8,37.2-73.6,74.3-110.4,111.5c-1.4,1.4-2.5,3-3.7,4.5C40,240,39.7,240,39.3,240z"/>
         </g>
         </svg></span>
            </div>
          </div>`;
            },
            propertyResolver: ((elementName) => {
                if (elementName === 'gallery_images') {
                    return 'inlineGalleryImages';
                }
                return elementName;
            })
        });
    }
}
