import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let unsubscribeSubject = new Subject();

class HeaderRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  fetchMainPage() {
    client.items()
      .type('header')
      .depthParameter(2)
      .toObservable()
      .pipe(takeUntil(unsubscribeSubject))
      .subscribe(response => {
        //console.log(response.items);
        //document.getElementById('main_body').innerHTML = response.items[0].main_body.resolveHtml();
        this.setState({
          loaded: true,
          header: response.items
        });
      });
  }
  unsubscribe() {
    unsubscribeSubject.next();
    unsubscribeSubject.complete();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.fetchMainPage();
  }
  componentDidUpdate() {
}

scrollToTributes(e) {
  var element = document.getElementById("TheTributeForm");
  if (element != null) {
    setTimeout(function (){
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 1000);
  } else {
    window.location.href = '/#Tributes';
  }
}
  render() {
    if (this.state.loaded) {
      return (
        <div>



          {this.state.header.map((page) => {
            var myLoc = window.location.pathname;
            var headerClass = "Header HeaderWhite";
            if (myLoc === "" || myLoc === "/") {
              headerClass = "Header";
            }
            return (
              <div key={page.system.name}>
                  <div className={headerClass}>
                      <div className="Title">
                          <a href="/">
                              <div id="header_copy" className="header_copy" key={page.header_copy.value}
                dangerouslySetInnerHTML={{ __html: page.header_copy.value }} />
                </a>
                          </div>
                          <div className="RightLinks">
                          <a href="/eulogies/"><div className="RLLink" id="Eulogies">Eulogies</div></a>
                          <a href="/remembrance/"><div className="RLLink" id="Remembrance">Remembrance</div></a>
                          {/* <a href="/king-co-bar-bulletin/"><div className="RLLink" id="Lawjournal">Bar Bulletin</div></a> */}
                          <a href="/stories/"><div className="RLLink" id="Stories">Stories</div></a>
                          <div className="RLLink" id="Leaveatribute" onClick={this.scrollToTributes}>Leave a tribute</div>
                          </div>
                          </div>
</div>
            )
          })}

        </div>
      );
    } else {
      return (
<div className="Header"><div className="Title"><a href="/"><div id="header_copy_black" className="header_copy_black">Celebrating the life of William H. Gates II</div></a></div></div>
      )
    }
  }
}

export default HeaderRender;