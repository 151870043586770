import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

let unsubscribeSubject = new Subject();

class ArticleStartRender extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }

    fetchMainPage() {
        client.items()
            .type('article_start')
            .depthParameter(2)
            .toObservable()
            .pipe(takeUntil(unsubscribeSubject))
            .subscribe(response => {
                //console.log(response.items);
                //document.getElementById('main_body').innerHTML = response.items[0].main_body.resolveHtml();
                this.setState({
                    loaded: true,
                    article_start: response.items
                });
            });
    }
    unsubscribe() {
        unsubscribeSubject.next();
        unsubscribeSubject.complete();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    componentDidMount() {
        this.fetchMainPage();
    }
    componentDidUpdate() {
    }

    render() {
        if (this.state.loaded) {
            return (
                <div>


                    {this.state.article_start.map((page) => {
                        return (
                            <div className="article_start" key={page.system.name}>
                                <div className="StartQuote">
                                    <span id="quote_copy" className="quote_copy" key={page.quote_copy.value}
                                        dangerouslySetInnerHTML={{ __html: page.quote_copy.value }} /> - 
                                    <span id="quote_by" className="quote_by" key={page.quote_by.value}
                                        dangerouslySetInnerHTML={{ __html: page.quote_by.value }} />
                                </div>
{/*                                 <div className="TitleBylineDate">
                                    <div id="TBDtitle" className="TBDtitle" key={page.title.value}
                                        dangerouslySetInnerHTML={{ __html: page.title.value }} />
                                    By <span id="TBDbyline" className="TBDbyline" key={page.byline.value}
                                        dangerouslySetInnerHTML={{ __html: page.byline.value }} />
                                    <span id="TBDdate" className="TBDdate" key={page.date.value}
                                        dangerouslySetInnerHTML={{ __html: page.date.value.toLocaleDateString('Default',{month:'long'})+" "+page.date.value.toLocaleDateString('Default',{day:'numeric'})+", "+page.date.value.toLocaleDateString('Default',{year:'numeric'}) }} />
                                </div> */}
                            </div>
                        )
                    })}






                </div>
            );
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}

export default ArticleStartRender;