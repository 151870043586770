import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import RichTextElement from './RichTextElement';
import { InlineQuoteMod } from './Models/inline_quote_mod';
import $ from 'jquery'; 

let unsubscribeSubject = new Subject();
let MainPageObject =[];
let SlideCount = 0;
let SlideActive = 0;
let disableSlideNav = false;
let _this = "";

class MainPageRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  fetchMainPage() {
    client.items()
      /* .type('main_page_type') */
      .equalsFilter('system.codename', 'new_main_page')
      .depthParameter(2)
      .toObservable()
      .pipe(takeUntil(unsubscribeSubject))
      .subscribe(response => {
        //console.log(response.items);
        document.getElementById('main_body').innerHTML = response.items[0].main_body.resolveHtml();
        this.setState({
          loaded: true,
          MainPage: response.items,
          MainPageItems: response.linkedItems
        });
      });
  }
  unsubscribe() {
    unsubscribeSubject.next();
    unsubscribeSubject.complete();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.fetchMainPage();
  }
  componentDidUpdate() {
    this.placeCarouselLIs();
    this.buttonClicks();
    this.PositionSlides();
    window.addEventListener("resize", this.resizeSlidesFunction.bind(this));
}
resizeSlidesFunction() {
  //_this.PositionSlides();
    _this.GotoSlide(0);
}
buttonClicks() {
  _this = this;
  $(".left").click(function (e) {
    _this.PrevSlide();
  });
  $(".right").click(function (e) {
    _this.NextSlide();
  });
  $(".carousel-bar").click(function (e) {
    _this.GotoSlide($(this).attr("data-slide-to"));
  });
}
PrevSlide() {
  if (!disableSlideNav) {
    var cw = $(".carousel").outerWidth(); 
    //console.log("Prev: "+SlideActive);
    var HolderLeft = $(".carousel-inner").css("left");
    HolderLeft = HolderLeft.replace("px","");
    //console.log("HolderLeft: "+HolderLeft);
    disableSlideNav = true;
    $( ".carousel-inner" ).animate({
      left: parseInt(HolderLeft)+cw
    }, 1000, function() {
      // Animation complete
    SlideActive = parseInt(SlideActive)-1;
    _this.setBars();
    disableSlideNav = false;
    _this.PositionSlides();
    });
  }
}
NextSlide() {
  if (!disableSlideNav) {
    var cw = $(".carousel").outerWidth(); 
    //console.log("Next: "+SlideActive);
    var HolderLeft = $(".carousel-inner").css("left");
    HolderLeft = HolderLeft.replace("px","");
    //console.log("HolderLeft: "+HolderLeft);
    disableSlideNav = true;
    $( ".carousel-inner" ).animate({
      left: parseInt(HolderLeft)-cw
    }, 1000, function() {
      // Animation complete
    SlideActive = parseInt(SlideActive)+1;
    _this.setBars();
    disableSlideNav = false;
    _this.PositionSlides();
    });
  }
}
setBars() {
  if (SlideActive>=SlideCount) {
    SlideActive=0;
  }
  if (SlideActive<0) {
    SlideActive=(SlideCount-1);
  }
  //console.log("SlideActive: "+SlideActive);
  $(".carousel-bar").each(function () {
    $(this).removeClass("active");
    if ($(this).attr("data-slide-to") == SlideActive){
      $(this).addClass("active");
    }
  });

}
GotoSlide(slideNum) {
  //console.log("Goto: "+slideNum);
  var cw = $(".carousel").outerWidth(); 
  SlideActive = slideNum;
  $(".carousel-inner").html($(".carousel-inner-saved").html());
  $( ".carousel-inner" ).animate({
    left: -cw*slideNum
  }, 0, function() {
    // Animation complete
  _this.PositionSlides();
  _this.setBars();
  disableSlideNav = false;
  });
}
PositionSlides() {
  $(".carousel-inner").width((SlideCount*100)+"%");
  var HolderLeft = $(".carousel-inner").css("left");
  if (HolderLeft != undefined) {
    HolderLeft = HolderLeft.replace("px","");
    var cw = $(".carousel").outerWidth(); 
    if (HolderLeft>="0") {
      $(".carousel-inner").prepend($(".carousel-inner").find(".item").last());
      $(".carousel-inner").css("left",-cw+"px");
      //console.log("ShiftLeft");
    } else if (HolderLeft<=-((SlideCount-1)*cw)) {
      $(".carousel-inner").append($(".carousel-inner").find(".item").first());
      $(".carousel-inner").css("left",-(SlideCount-2)*cw+"px");
      //console.log("ShiftRight");
    }
  }
}
placeCarouselLIs() {
  var itemNum = 0;
  $(".kc-linked-item-wrapper").each(function () {
    $(this).remove();
  });
  $(".carousel-inner").find("p").not(".item p").each(function () {
    $(this).remove();
  });
  $(".item").first().addClass("active");
    $(".carousel-inner").find(".carousel-bar").each(function () {
      SlideCount += 1;
      if (itemNum == 0) {
        $(this).addClass("active");
      }
      $(this).attr("data-slide-to",itemNum);
      itemNum += 1;
      $(this).parent().parent().find(".carousel-indicators").append($(this));
    });
    itemNum = 0;
    $(".carousel-inner").find(".item").each(function () {
      $(this).attr("data-slide-num",itemNum);
      itemNum += 1;
    });
    //save orig set for resetcarousel-inner-saved
    $(".carousel-inner-saved").html($(".carousel-inner").html());
  }

  render() {
    if (this.state.loaded) {
      MainPageObject = this.state.MainPage[0];
      return (
        <div>

        </div>
      );
    } else {
      return (
        <div>
          </div>
      )
    }
  }
}

export default MainPageRender;