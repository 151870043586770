import React, { Component } from 'react';
import './App.css';

// Imports routing dependencies from react-router-dom and our components
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import MainPageRender from './MainPageRender';
import HeaderRender from './HeaderRender';
import HeroLockupRender from './HeroLockupRender';
import ArticleStartRender from './ArticleStartRender';
import FooterLockupRender from './FooterLockupRender';
import My404Render from './My404Render';
import TributeForm from './TributeForm';
import TributeItems from './TributeItems';
import TermsOfUse from './TermsOfUse';
import Eulogies from './Eulogies';
import LibbyEulogy from './libby-eulogy';
import BillEulogy from './bill-eulogy';
import MimiEulogy from './mimi-eulogy';
import JonathanEulogy from './jonathan-eulogy';
import Remembrance from './Remembrance';
import Obituary from './Obituary';
/* import LawJournal from './LawJournal'; */
import Stories from './Stories';
import PrivacyPolicy from './PrivacyPolicy';

const pathNames = ['/eulogies', '/eulogies/', '/libby-eulogy', '/libby-eulogy/', '/bill-eulogy', '/bill-eulogy/', '/mimi-eulogy', '/mimi-eulogy/', '/jonathan-eulogy', '/jonathan-eulogy/', '/obituary', '/obituary/', '/remembrance', '/remembrance/', '/stories', '/stories/', '/404', '/404/', '/privacy-policy', '/privacy-policy/', '/terms-of-use', '/terms-of-use/', '/'
]


class App extends Component {
  
  render() {
    
  if (!pathNames.includes(window.location.pathname)) {
    return (
      <div className="App">
        
        {/* Specifies routes and their components */}
        <Router>
          <Redirect to="/404" />
            <Route path="/" component={HeaderRender} />
            <div>
            <Route exact path="/404/" component={My404Render} />
            <Route path="/" component={FooterLockupRender} />
          </div>
        </Router>
      </div>
    );
    window.location.reload();
  } else {
    return (
      <div className="App">

        {/* Specifies routes and their components */}
        <Router>
          <Route path="/" component={HeaderRender} />
          <Route exact path="/" component={HeroLockupRender} />
          <Route exact path="/" component={ArticleStartRender} />
          <div id="main_body" className="main_body">
            <Route exact path="/" component={MainPageRender} />
            <Route exact path="/privacy-policy/" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use/" component={TermsOfUse} />
          </div>
            <Route exact path="/404/" component={My404Render} />
            <Route exact path="/obituary/" component={Obituary} />
            <Route exact path="/remembrance/" component={Remembrance} />
            <Route exact path="/eulogies/" component={Eulogies} />
            <Route exact path="/libby-eulogy/" component={LibbyEulogy} />
            <Route exact path="/bill-eulogy/" component={BillEulogy} />
            <Route exact path="/mimi-eulogy/" component={MimiEulogy} />
            <Route exact path="/jonathan-eulogy/" component={JonathanEulogy} />
            {/* <Route exact path="/king-co-bar-bulletin/" component={LawJournal} /> */}
            <Route exact path="/stories/" component={Stories} />
          <div>
            <Route exact path="/" component={TributeForm} />
            <Route exact path="/" component={TributeItems} />
            <Route path="/" component={FooterLockupRender} />
          </div>
        </Router>
      </div>
    );
  }
  }
}

export default App;