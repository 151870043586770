import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class My404Render extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div className="my404page placeholderPage">
Page not found

            </div>
        );
    }
}

export default My404Render;