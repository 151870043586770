import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class Remembrance extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Remembrance" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

<div className="main_body">

<div className="articleTop">
<div className="TBDtitle">Remembrance</div>
<div className="article_top_byline">By <span className="TBDbyline">Kristianne Blake, Bill Gates, and Libby MacPhee</span><span className="TBDdate">October 2, 2020</span></div>

</div>

{/* <p>We put up this site as a space for those who want to join in celebrating our dad, known to many as Bill Sr., and his remarkable life. Thank you for visiting.</p> */}
<p>We miss our dad more than we can express. But we are feeling gratitude as well as grief. Dad’s passing was not unexpected—he had had Alzheimer’s for several years—so we have all had some time to reflect on just how lucky we are to have had him in our lives. And we have heard enough from people around the world to know we are not alone in these feelings. Our dad meant so much to so many.</p>
<p>He grew up in the Navy town of Bremerton, WA. He went to Bremerton High School and was active in Boy Scouts, where his troop spent three summers building a log cabin from the ground up, and he learned his first lesson about the power of people working toward a common goal. During his high school summers, he put in long, exhausting hours working for our grandfather’s furniture store.</p>
<p>After completing one year at the University of Washington, he reported for duty in the U.S. Army. He completed officer candidate school and then was shipping out to Japan when it surrendered. He spent a year in war-torn Tokyo, which provided him with one of his first opportunities to consider our shared humanity and its ability to unite people regardless of their backgrounds.</p>
<p>He returned to the UW to earn an undergraduate degree in 1949 and a law degree the next year. During that time, he gained a lifelong passion for the Husky Nation and met the powerful, confident woman who became our mom, Mary Maxwell.</p>
<p>Bill’s friend Warren Buffett often talks about the lottery of birth. We won that lottery. Our mom and dad started us off with many advantages including great schools, constant encouragement, and patience with our quirks and mistakes.</p>
<p>They loved creating traditions with family and friends. For years they hosted a roller-skating party for friends and their children around Christmastime. At some point during the party, Dad would disappear, and then a few minutes later, Santa would appear. The way he would get around that roller rink with a Santa suit on was pretty impressive. It was just one of many examples of something he did with great joy. When we were growing up, we always knew our dad’s love and support were unconditional. No matter what risks we took or what mistakes we made, we knew our dad would be in our corner even if we failed miserably. He wasn’t prescriptive or domineering, and yet he never let us coast along at things we weren’t good at, and he always pushed us to try things we hated or didn’t think we could do.</p>
<p>As we got older, we came to appreciate our dad’s quiet influence on almost everything we did in life. In Microsoft’s early years, Bill turned to our dad at key moments to seek his legal counsel.</p>
<p>Incidentally, our dad played a similarly influential role for Howard Schultz of Starbucks. When a powerful Seattle insider tried to purchase Starbucks just before Howard closed the deal to do so, Dad helped him out. “Once we were face-to-face, I became very emotional as I explained the story. You empathized with the situation,” Howard wrote to our dad years later. Dad then walked Howard across the street and demanded to see the businessperson. “You stood in front of the man, in his own office, and called his behavior what it was: despicable. I was in awe at what you said next. ‘You are not going to steal this kid’s opportunity. You are going to stand down.’” And he did.</p>
<p>Our dad once said, “There are intersections of integrity and temptation in every career and every life. The challenge is to do the right thing no matter what.” He <em>always</em> practiced what he preached.</p>
<p>Dad modeled for us an incredible work ethic. As he put it in his wonderful book <em>Showing Up for Life</em>, “People often ask me why … I still rise early every morning and drive to an office to work. I usually respond with a predictable three-word answer: I like working. I like the challenge of having to make decisions where there’s always a risk of failing. I find that exhilarating.”</p>
<p>One of the biggest lessons he taught us is to have a passion for what you do. He always exhibited that through his practice of law: how he practiced, how he engaged with the legal community. His passion for justice. It was evident all the time that he was engaged in activities that he had passion for, and that’s what made his life whole.</p>
<p>Dad was also one of the most important civic leaders in our region. He led the Seattle/King County Bar Association and the Washington State Bar Association. He served on the boards of the Greater Seattle Chamber of Commerce, King County United Way, and Planned Parenthood. He founded the Technology Alliance, which focused on expanding technology employment in Washington. He chaired the Seattle Public Schools Levy and was one of the most visible advocates for maintaining an estate tax—reflections of his deep commitment to social and economic equity. And he was the ultimate supporter of his beloved University of Washington, serving on the board of regents for 15 years and helping to transform it into one of the greatest public universities in the world. Even in the later stages of Alzheimer’s, Dad could sing all the lyrics to “Bow Down to Washington.”</p>
<p>His laser focus in all this civic work was on helping this region thrive economically, culturally, and educationally—and he had a meaningful, lasting impact on all three. “This city would be very different without Bill having lived here,” his longtime law partner Marty Smith told a journalist. He loved this region he always called home. He wanted it to be a great place for people of all backgrounds to live and find opportunity.</p>
<p>Mom and Dad also talked to us constantly about the value of giving back. Throughout our childhoods, they taught us by example what generosity looked like in how they used their time and resources.</p>
<p>One of the reasons our dad was so generous was because of a special gift he received on his 16<sup>th</sup> birthday. Our aunt Merridy saved up $85 to buy him a used 1930 Model A, even though their father had not let Merridy learn how to drive. As Dad later wrote in <em>Showing Up for Life</em>, “Merridy’s generosity—when she had been denied the opportunity to drive herself—was something I have never forgotten... Merridy’s gift to me was more than a car—she gave me my first real lesson in what it means to be a truly generous person.”</p>
<p>Over the years, we have heard literally hundreds of stories about how our dad paid that gift forward—not just in the form of money but also through his time, friendship, mentorship, and simple acts of kindness. As one publication wrote a few years ago, “When family, colleagues and friends begin a story about Sr.’s public accomplishments, it’s not long before they sidetrack into very personal anecdotes that illustrate his deep humanity. Bill Sr. was the first to call after their wife’s ovarian surgery. Despite being a high-powered corporate attorney, he personally handled their adoption case. He knew by name the parking attendants in his firm’s garage, and would inquire about their families.”</p>
<p>&nbsp;One example is the way he valued and respected the wonderful professionals who helped take care of him when his Alzheimer’s became serious. When he came over for dinner, he always wanted to be sure there was a seat at the table for them. There are hundreds more examples like that.</p>
<p>Another wonderful story comes from Ryan Rippel, who worked with our dad at the Gates Foundation for a few years before returning to school. A few months before Ryan’s graduation from law school, our dad called him and said, “Ryan, it occurs to me that you do not have a large family to celebrate graduation with you this spring. I’ve decided that Mimi and I are going to come to Boston and be there to watch you graduate.” Dad remembered that Ryan had lost his father to a car accident and his mother to cancer and therefore wouldn’t have much of a cheering section.</p>
<p>One of the many things we admired about our dad was his ability to change. When we were growing up, he was a work-oriented kind of dad. But as he aged, he softened around the edges almost to the point of being squishy. His emotions were right out front. He was passionate and deeply engaged in everything he did, which meant his emotions tended to show themselves a lot. He definitely let his emotional side show when Bill and Melinda shared the news that they were going to get married. As Melinda put it once:</p>
<div className="InlineQuoteHolder"><div className="InlineQuoteModCopy"><p>“We sat down at a big, U-shaped sofa. Bill and I were sitting on one part of the U, and his parents were sitting across from us, and there were two little glass coffee tables between us. Bill said, ‘You know, I’ve asked Melinda if she’ll marry me.’</p>
<p>“His mom started beaming. The reaction from his dad was not what I expected. He jumped up and ran over to us, nearly crashing into the glass coffee tables. He gave us both a <em>huge</em> bear hug. He was so exuberant and excited. And it made me feel so welcomed into the family.”</p></div><div className="InlineQuoteModBy">- Melinda</div></div><p></p>
<p>Not long after our mom died, Bill and Melinda turned to our dad to help them start making substantial philanthropic gifts and later to help them run their foundation. &nbsp;The Bill &amp; Melinda Gates Foundation would not be what it is today without him. He started the foundation’s work in global health; created the Gates Cambridge Scholars Trust; and guided the foundation’s major investments in human services, education, and culture in the Pacific Northwest.</p>
<p>Most important, he shaped the values of the foundation. He was the one who got down on hands and knees in the lunchroom when he noticed that a soda can had rolled under a refrigerator. (When a colleague told him there were 300 people in the building who would have retrieved that can if he had asked, Dad responded, “I saw it, so it was my responsibility to do something about it.”)</p>
<p>He was collaborative, judicious, and serious about learning. He was great at stepping back and seeing the big picture. He was the quickest to tear up when he saw suffering in the world.&nbsp; As former Gates Foundation CEO Sue Desmond-Hellmann put it in a note to him a few years ago, “You are the foundation’s conscience. Thank you for always reminding us why we are here.”</p>
<p>People who came through the doors of the Gates Foundation felt honored to work with our dad. He saw the best in everyone and made everyone feel special. As Jeff and Tricia Raikes wrote to him on the occasion of his 90<sup>th</sup> birthday, “You are beloved at the Gates Foundation…. You’re a great listener. You have a gracious manner. Whether it is with a global leader like President Carter or an at-risk youth in Seattle, you always genuinely convey warmth and caring that endears and inspires.”</p>
<p>Finally, our dad had profoundly positive influences on our most important roles—as spouses and parents. When we are at our best, we know it’s because of what we learned from both of our parents about &nbsp;honoring individuality and guiding children’s choices with love and respect. We are profoundly grateful to be surrounded by our amazing family: Dad’s beloved second wife, Mimi Gardner Gates; our spouses, John Blake, Melinda French Gates, and Nicholas MacPhee; and all of our children--Kerry and Sully Blake, Jennifer, Rory, and Phoebe Gates, and Emmy, Steve, and Mary Armintrout. The love of a large and caring family may be the greatest gift our father gave us.</p>
{/* <p>If you care to share any stories about how our dad showed up in your life, we encourage you to do so. We know he touched the lives of people around the world, and hearing about the kindness and generosity he displayed is a source of comfort for all of us.</p>
<p>Thank you.</p> */}
<p><br/></p>
<p><br/></p>

            </div>

            </div>
        );
    }
}

export default Remembrance;