import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class JonathanEulogy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Jonathan" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">

<div className="articleTop">
<div className="TBDtitle">Eulogy delivered by Jonathan Roberts</div>
<div className="article_top_byline"><span className="TBDdate">October 10, 2020</span></div>
</div>
<p>Hello. I'm Jonathan Roberts. I would like to welcome you  to Epiphany. This is Mimi's parish and it is also where Elizabeth and I attend so it is nice to be  having this service here. I also want to thank Mimi and the rest of the family  for the great honor of speaking to you today and for sharing my love and  appreciation for Bill Senior. For me, and quite probably all of us here, Bill  is my foremost role model for what it is to be a human, a man, a father, a  husband, a friend, and a citizen. </p>
<p>In the next few minutes, I want to share with you five  episodes of my life and the lesson from Bill that I learned from each of them. </p>
<p>Episode one: Camelot. On the Hood Canal across from Gateaway  and about ten miles towards Belfair is a beat up and tired resort called Cheerio.  This is where ten families spent two weeks a year for thirteen summers. It was  our Camelot. A magical place with time spent swimming, playing tennis, capture  the flag, campfires on the beach where Tony Frederick led us in singing &quot;Alouette,  gentille alouette.&quot; And every day ended with each family parading back to  their cabins while we whistled &quot;Bridge Over the River Kwai.&quot;  Basically, it's an overachiever's version of Mayberry. The Alderbrook Fourth of  July activities give each of the grandkids a sense of Cheerio.</p>
<p>And every kingdom has its king. In this case, the king  was Bill Senior. In the Cheerio vernacular Bill was called the mayor. And in  Bill's town we were safe, we were loved, we were celebrated, and we were  supported. </p>
<p>The lasting impact of Bill's kingship is best evidenced  in his own family. His gravitas, love, and support were an anchor to his  family. He was the person who held the kite string that allowed each member of  his family to soar. From the kite's vantage point, it might seem best to cut  the string and be free. But we all know how that works. It gets stuck in a tree  or flutters to the ground. Bill held that string tight and provided the support  and encouragement that allowed Mary to soar and break Seattle's glass ceilings.  Mimi to be a major figure in the Seattle community and the global art world.  And each of his kids and grandkids to be fully themselves and engage in their  communities and the world. Lesson: Hold the string for the one's you love and  let them soar.</p>
<p>Episode two: Capsize. My childhood was mostly smooth  sailing. On April 10, 1979 when I was 15 my boat capsized when my mother was  killed in a pedestrian car accident. I found myself alone without a life jacket  in cold water. No boats were in sight and I could not see the shore. I was scared.  This condition did not last long. Very soon a boat came alongside and two sets  of hands reached down and pulled me on board their boat. The hands, those were  the hands of Bill and Mary Gates. Every good thing in my life can be traced back  to that rescue. My debt to the Gates family is incalculable.</p>
<p>My circumstance was unique but being rescued by the Good  Boat Gates is not. Under Bill Senior&rsquo;s initial leadership and enduring  inspiration, this boat has rescued millions and most being in most desperate  circumstances than mine. That said, I believe that one of the greatest legacies  of Bill Senior is that that original boat has expanded into a fleet comprised  of the Good Boats Gates, Blake, Armentrout, and MacPhee. They each sail in  different waters but they are all focused on service and care. The role modeling  from Bill Senior is so strong that the whole family is wired to care,  contribute, and engage. I would like to note that from the vantage point of the  person in the water, it is not the size of the boat that matters, but the hands  that pull you in. Lesson: Lend a hand to those most in need. </p>
<p>Episode three: Renaissance. Almost exactly 15 years  later, the Gates family lost their mother. Mary courageously battled cancer for  a year and the support provided her by Bill Senior and the family has only been  exceeded by the support Bill Senior received in the hands of Mimi, each of the  kids, and the remarkable caregivers present today.</p>
<p>A few weeks after Mary died Bill Senior called me at  Microsoft to ask if I wanted to have coffee. This call surprised me. I knew  Bill well, but from a bit of a distance. He was not exactly George Washington  since to the best of my knowledge, George Washington never dressed up as Santa  Claus and roller-skated. But he was close. A revered but not entirely  approachable figure. Bill's renaissance was remarkable. He went from being a  1950's dad into taking on elements of Alan Alda. He laughed more easily, hugged  more often, and had a lighter step.</p>
<p>One day when I arrived at Burgermaster for our breakfast,  he looked straight at me and said, &quot;I shacked up last night.&quot; I can  assure you this is exactly what he said. Who could forget? Mimi had entered his  life. And he was a giddy schoolboy. This wonder, thrill, attraction, and  delight in Mimi never left him. Bill showed us all that life goes on. He  demonstrated to all of us how to break out of a rigid definition of manhood and  become a fully formed, sensitive, caring, available, yet always strong and  gentle man. We all benefited from this renaissance and Bill Senior most of all.  Lesson: Live abundantly. </p>
<p>Episode four: Return of the Mayor. I served on the  University of Washington Foundation Board with Bill Senior for almost 10 years  and I sat through dozens of meetings with him. The most senior, capable, and  engaged people from business, government, academia, not-for-profit, and the  arts participated in these meetings. Yet without exception, Bill was always the  most senior person in the room. His character and gravitas made him the person  who people turned to. His compliments and mild criticisms were the ones most  deeply felt. But Bill brought more to these meetings than his great mind. He  brought his heart. He loved the people of the University of Washington. He also  loved the people, mission, and organization of the foundation, his law firm,  the city, and the state.</p>
<p>This is evidenced by the many initiatives he led and  organizations that he founded. At the last UW Foundation Annual Gala that Bill  Senior spoke at, he had the whole audience in Suzzallo Library up on its feet  singing, &quot;Bow down to Washington.&quot;  It struck me in that moment that just like at  Cheerio, he was the mayor. In fact, he was the mayor of every group or  organization he was part of. Lesson: Show up and engage with your heart and  mind. </p>
<p>And finally, episode five: Revealed. </p>
<p>It is said that age reveals us and I do not believe there  is anything that reveals someone more cruelly, and in Bill's case more gloriously,  than Alzheimer's disease. Our breakfast and lunch meetings transitioned to  visits to his home where he was so ably cared for by Mimi, his kids, Joe, Vaughn,  and many others.</p>
<p>I have three quick vignettes to share that I believe  reveal Bill's character. </p>
<p>Vignette one. Once when I arrived and Joe answered the door,  Bill Senior leapt out of his chair to greet me. This was a risky maneuver. Joe  quickly turned to stabilize him and it showed me that courtesy and graciousness  were deeply embedded in Bill. The subject matter might have escaped him, but  his manners never left him. </p>
<p>When conversation became difficult, I used to show him  family pictures of his book <em>Showing Up  For Life</em>. Images of Kristi and John, Libby playing baseball, Trey in his  cub scout uniform, etcetera. One day he looked up and reached out and stroked my  head. I knew that given the context of what we were looking at, in his mind he  was stroking the head of each of his kids. He loved his family deeply. </p>
<p>In the last few months, Elizabeth and I visited him at  Gateaway and he was very disoriented but he had one dominant preoccupation. He  kept asking for &quot;the lady,&quot; &quot;the pretty lady,&quot; &quot;my  lady.&quot; The lady of course was and is Mimi. When Bill ceased to know almost  everything else, he knew who his girl was. The schoolboy and his girl to the  end. Lesson: Character matters and is revealed. </p>
<p>Thank you, Bill, for these lessons and so much more. We  will do our best. </p>
                <br/><br/>
                </div>
            </div>
        );
    }
}

export default JonathanEulogy;