import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class LibbyEulogy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Libby" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">

<div className="articleTop">
<div className="TBDtitle">Eulogy delivered by Libby MacPhee</div>
<div className="article_top_byline"><span className="TBDdate">October 10, 2020</span></div>
</div>
<p>Good afternoon.  Now that you've heard from the other Bill Gates, on behalf of Kristi and  myself, I get to speak for the daughters in the family. </p>
<p>There are many,  many people who could be up here today talking eloquently about my dad's  impact. And like my brother said, we have cherished the stories in the notes  and emails we've received, and the authors of those stories could just as well  be here to paint a perfect picture of my dad's lasting influence. But as Warren  Buffett has said, the most important event in which you will ever participate  is the ovarian lottery. And I'm up here speaking because I was one of three  lucky winners. </p>
<p>It's an awesome  task to try to sum up the life of a figure as legendary as Dad in a few  minutes. But it's not his legendary status I want to talk about today. It's who  he was as a husband, a dad, a father-in-law, a Poppy, and a friend. </p>
<p>It would be a  tall order for us to live up to the person he was. In fact, one of his  granddaughters said, &quot;He's like a measuring stick, we're trying to live up  to, but one we'll never reach in life or in height.&quot; Despite all the  hoopla in the press, I think he would want me to acknowledge here that he  wasn't perfect. I know that because when Mom passed away, and her picture was  all over the front pages of the newspaper, one day he held up a copy, looked up  at us and said, &quot;You know, your mother was no saint.&quot; Well, he wasn't  either. For example, when you were out with him at a restaurant, and the coffee  wasn't as hot as he wanted it to be, he would sometimes lose his cool. Another  fault of Dad's was that he could never say no. He said yes to any role, or any  event in the community that anybody wanted him to show up for and he said yes  at home even when he didn't want to.</p>
<p>My dad was a  reluctant executor of my mother's grand plans, carrying out every unglamorous  task on his honey do list without complaint. Those tasks included preparing for  parties and implementing whatever punishment my mom thought fit for our  childhood misbehavior. When I was in college, he made a trip to California to  interview a man I was dating just to make sure this guy's intentions were true.  This was not my dad's idea of a good time but he did it in the service of  parenting from one voice. </p>
<p>More recently,  we all went on a glamping trip in Montana. Each of us kids had planned in  advance to take turns to sit with him back at camp, while everybody else went  out on various adventures. Well, he defied our expectations, and went right  along with us, to go river rafting, ATVing, and go-carting. Imagine that big  body all crammed in the go-cart. It actually really did happen. So  &quot;no&quot; was a rarely uttered word.</p>
<p>Thirdly, Dad  was hard on himself to a fault. Everybody here is very familiar with,  &quot;Shut up, Bill,&quot; a statement of self-criticism, not a directive to my  brother. </p>
<p>In the last few  years, I occasionally had the privilege of taking him to his psychiatrist  appointment and the most common complaint he expressed about his illness was  that he wasn't, and I quote, &quot;Adding any value anymore.&quot; The truth is  that he spent his lifetime adding value, and he added value until the day he  died. He added value in all of the ways you read about in the paper, and in  private ways, too. Sunday dinners, Cheerio, doing things in order of age,  summers at Hood Canal, competitions, games, matching pajamas at Christmas.  These traditions have become, as he described, &quot;Monuments that bring  predictability and shape and form to our lives.&quot; How valuable is that?</p>
<p>Dad was always  tenderhearted. I remember how tenderheartedly he approached telling me about  Mimi, over lunch, shortly after they began dating. He so carefully balanced the  excitement, giddiness, he felt with having Mimi in his life, with the knowledge  that I was still grieving our mom. But his approach was exactly right.</p>
<p>Mimi, you  became an integral part of our family, and added new traditions, like the  10-year-old trips with the grandkids, and gift bags at Christmas, and these  inspired Dad to engage with his grandkids in new and different ways. Another of  Mimi's traditions was an annual Chinese New Year celebration, co-hosted by Charlotte  and Fred Merritt. One of his grandsons recalled that each year they read <em>The Great Race</em>, the story of how every  animal earned its place in the Chinese Zodiac and during the reading, they each  acted out their roles. He remembered Poppy as the most committed to his  character. &quot;He was down on his knees and elbows with each pointer finger  appended to the side of his head, to represent horns. I believe he was the  ox?&quot; He would make loud ox sounds while he rammed his horns into the other  animals. There was a part in the race where the other animals climbed on top of  the ox, and he happily played along. While oxes may not be tender, Dad's  embracing of the role spoke to the all-in way he loved, lived and led. </p>
<p>I can't use the  word tender without acknowledging the closest thing to sainthood that I have  ever seen, Dad's friends and colleagues who continued to spend time with him  long after he remembered them. </p>
<p>And Team Bill.  Where do I even begin? It was a privilege to watch you work, and a privilege to  have full confidence, 100% of the time, that he was in good hands. You were a  steady presence for him. You had endless patience. You let him lead, even  following his navigation to go left when it made way more sense to go right. You  listened so carefully, intuitively knowing what he needed, even if it was his  fourth bowl of ice cream in a day. You were tender, and we will never forget  the loving hands that nurtured him in his final hours. Bonnie, a quarter  century with him. Daniel, Joe, Bong, Ron, AJ, Mike E., Mike C., Jeff, Jamie and  Katlyn. We learned so much from you, and from all of us, and from Boss, we  thank you. </p>
<p>Section D, Row  F, Seat 32. What is that, you may ask. That was the last seat Dad occupied at  Husky Stadium, a place he visited most fall Saturdays for over 50 years. Seats  mattered to Dad. Be it the fact that we sat at the same seats at the family  dinner table our entire lives, or the need for an airplane seat with plenty of leg  room, or holding a meeting at a seat at the Burgermaster, or a seat in front of  the theatre, so he could hear, or a seat on a UW Board of Regents. His  commitment to justice meant he always wanted equal access to seats at the table  where things were happening, and where decisions were being made. He  demonstrated that in the larger community by supporting things like the law  school scholarships for students of color, and equity for women in the  workplace. </p>
<p>But he did it  at home, too. In recent years, he always made sure his caregivers had a seat at  the table, and a full plate of food. He wanted them to be included, and he was  always thanking them by tenderly patting them on the leg. The recliner seat in  which he spent a lot of time in recent years, became a central gathering place  for visitors to come and have a seat next to him. And along those lines of him  wanting everyone to have a seat at the table, he was failing so in May, that  it's almost as if he willed himself to live through the summer to give his  wife, his kids, his grandkids one more chance to sit around the table with him. </p>
<p>We love you,  Dad. </p><br/><br/>
                </div>
            </div>
        );
    }
}

export default LibbyEulogy;