import { DeliveryClient, TypeResolver } from '@kentico/kontent-delivery';

import { ManagementClient } from '@kentico/kontent-management';
//import {InlineQuote} from './InlineQuoteResolver';
import {InlineQuoteMod} from './Models/inline_quote_mod';
import { InlineWideImage } from './Models/inline_wide_image';
import { InlineImageGallery } from './Models/inline_image_gallery';
import { InlineGalleryImage } from './Models/inline_gallery_image';

// configure type resolvers
let typeResolvers = [
  new TypeResolver('inline_quote_mod', () => new InlineQuoteMod()),
  new TypeResolver('inline_wide_image', () => new InlineWideImage()),
  new TypeResolver('gallery_viewer', () => new InlineImageGallery()),
  new TypeResolver('gallery_image', () => new InlineGalleryImage())
];

export const client = new DeliveryClient({ projectId: 'e0b22a14-27bc-00f7-249f-3904f8a101b0',
typeResolvers: typeResolvers });


export const managementclient = new ManagementClient({
  projectId: 'e0b22a14-27bc-00f7-249f-3904f8a101b0',
  apiKey: 'ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjgyNDc3YmNjYWEwMTQ5ZjU5ZWRjZjc3NDE2ZGYwNGQ5IiwNCiAgImlhdCI6ICIxNjAwMjIxNDUwIiwNCiAgImV4cCI6ICIxOTQ1ODIxNDUwIiwNCiAgInByb2plY3RfaWQiOiAiZTBiMjJhMTQyN2JjMDBmNzI0OWYzOTA0ZjhhMTAxYjAiLA0KICAidmVyIjogIjIuMS4wIiwNCiAgInVpZCI6ICI1ZWRmZmVjZWQxZTcwNzAwMTkyZDRhMDQiLA0KICAiYXVkIjogIm1hbmFnZS5rZW50aWNvY2xvdWQuY29tIg0KfQ.rg9tVeQEQlkQBRIM-nXNxcBVBrROcYdYzk1Lebn_eYY'
});