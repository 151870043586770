import React, { Component } from 'react';
import { client } from './config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class MimiEulogy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false
        };
    }
    unsubscribe() {
    }

    componentWillUnmount() {
    }

    componentDidMount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                
            <div className="TopImage">
            <canvas id="TopImageCan-Mimi" class="TopImageCan" ></canvas>
            <div className="topTitle"></div>
            </div>

                <div className="main_body">

<div className="articleTop">
<div className="TBDtitle">Eulogy delivered by Mimi Gates</div>
<div className="article_top_byline"><span className="TBDdate">October 10, 2020</span></div>
</div>
<p>If  Bill Gates is not in heaven, there's no hope for the rest of us. Those are the  words of Father Michael Ryan of St. James Cathedral. </p>
<p>Indeed,  William, Bill, Dad, Poppy, Boss, was an extraordinary human being who, as  you've heard from his children, was a distinguished lawyer and philanthropist  and the beloved patriarch of a super fine family whom he loved with all his  heart. </p>
<p>Thank  you, each one of you, for being a part of his long, wonderful life. I want to  single out, as Libby did, team Bill who for the last five and a half years  provided compassionate care and love, sustaining and supporting Bill and me  with unfailing commitment. To all of you, my heartfelt gratitude and love. </p>
<p>I  will speak to Bill's compassion, tell a few funny Bill stories and read an  ancient Greek love poem that succinctly captures our deep abiding love for one  another.</p>
<p>In  my mind, the singular quality that best characterizes Bill and his life is his  compassion. His enormous capacity to think beyond himself and focus on the  well-being of others. Be it family, friends, colleagues, or the  disenfranchised.</p>
<p>While  holding himself to the highest standards, he was never self-absorbed or  arrogant. Always humble, willing to show up, step up, and speak up. His  advocacy for gun control, the estate tax, and state income tax on the top one  percent exemplifies his strongly held values and beliefs, his desire to better  society, especially those less fortunate than he was. He loved helping Bill and  Melinda establish and grow the Gates Foundation. </p>
<p>Bill  was a gift. When I moved across the country to Seattle in 1994, he was a  surprise gift to me. We first met at the Seattle Art Museum. Perhaps our  romance was all the sweeter because we were in the second half of our lives.  Before our first date, Bill, close to 70 years old, told wonderful Bonnie  Clannan that he felt like a teenager and enlisted her help to wash the wheels  of his car.</p>
<p>After  our first date, we were both invited to the same dinner party and happened to  be seated next to each other. Wanting to make polite conversation, Bill turned  to me and asked, &quot;How are your domestic skills?&quot; I guess that's what  happens when you haven't dated for 40 years.</p>
<p>On  our second date, he took me to the movie <em>Pulp  Fiction</em>. What a choice. To test me, he bought two senior tickets. I was all  of 52 at the time. His sense of humor was downright impish. And where did we go  for dinner after the movie? Burgermaster, his favorite hangout.</p>
<p>During  our courtship when we were driving separate vehicles, Bill expressed his affection  at stoplights in Seattle by backing his Mercedes into my Saab, kissing my  bumper and putting his convertible up and down. All the time laughing joyously.</p>
<p>In  the last decade of life as Bill struggled with dementia, he often addressed  himself as Libby has mentioned, uttering &quot;Oh Bill, shut up.&quot; I  remember waking up in the middle of the night to Bill who himself was sound  asleep. And he was saying in a loud voice, &quot;Why are you looking at me? Am  I really that handsome? Oh Bill, shut up.&quot; </p>
<p>And  how lucky we were to live together for so many years. Our 24th wedding  anniversary was just one week before he slipped away. For our wedding day,  friends at Copper Canyon Press had this anonymous Greek poem translated and  printed on a broadside for us.</p>
<p>&quot;Think  how unspeakably sweet the taste of snow at midsummer. How sweet a kind spring  breeze after the gales of winter. But as we all discover, nothing is quite as  sweet as one large cloak wrapped around two lovers.&quot; </p>
<p>In  West Africa, when a person of big Bill's stature dies, they say, &quot;A great  tree has fallen.&quot; The bigger the person, the wider the roots, the more  giant the spread of the canopy that gave shade and protection to others. Bill  was precisely such a tree. </p>
<p>Bill,  I will love you forever. </p>
                <br/><br/>
                </div>
            </div>
        );
    }
}

export default MimiEulogy;